import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import elementMass from "../data/ElectronMassData";
import TextField from "@mui/material/TextField";
import { MathJax } from "better-react-mathjax";

const MolarMass = () => {
  const [elementOne, setElementOne] = useState("");
  const [quantityElementOne, setQuantityElementOne] = useState("");
  const [elementTwo, setElementTwo] = useState("");
  const [quantityElementTwo, setQuantityElementTwo] = useState("");
  const [elementThree, setElementThree] = useState("");
  const [quantityElementThree, setQuantityElementThree] = useState("");
  const [elementFour, setElementFour] = useState("");
  const [quantityElementFour, setQuantityElementFour] = useState("");

  const findMolecularWeight = () => {
    const weight =
      elementOne * quantityElementOne +
      elementTwo * quantityElementTwo +
      elementThree * quantityElementThree +
      elementFour * quantityElementFour;
    return weight.toFixed(3);
  };
  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Molar Mass </Typography>
        <Typography variant="h6">
          What is the weight of 1 mole of any chemical compound?
        </Typography>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-one">Element 1</InputLabel>
          <Select
            // sx={{ width: "0.3" }}
            className="select-field"
            labelId="element-one"
            variant="standard"
            id="element-one"
            value={elementOne}
            label="Element"
            onChange={(e) => setElementOne(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementOne(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-two">Element 2</InputLabel>
          <Select
            // sx={{ width: "0.3" }}
            className="select-field"
            labelId="element-two"
            variant="standard"
            id="element-two"
            defaultValue={elementTwo}
            label="Element"
            onChange={(e) => setElementTwo(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementTwo(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-three">Element 3</InputLabel>
          <Select
            className="select-field"
            labelId="element-three"
            variant="standard"
            id="element-three"
            defaultValue={elementThree}
            label="Element"
            onChange={(e) => setElementThree(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementThree(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-four">Element 4</InputLabel>
          <Select
            className="select-field"
            labelId="element-four"
            variant="standard"
            id="element-four"
            defaultValue={elementFour}
            label="Element"
            onChange={(e) => setElementFour(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementFour(e.target.value)}
          />
        </FormControl>
        <Typography variant="body1" gutterBottom>
          Molecular Weight:{" "}
          <span className="electro">{findMolecularWeight()} amu</span>
        </Typography>
      </Box>
      <Typography variant="h3">Molar Mass Explained</Typography>
      <Typography paragraph>
        Molar mass is a fundamental concept in chemistry that plays a crucial
        role in various chemical calculations and is essential for understanding
        the behavior of substances on a molecular level. Molar mass is defined
        as the mass of one mole of a substance and is expressed in units of
        grams per mole (g/mol). This quantity represents the average mass of all
        the atoms or molecules in a sample, taking into account their relative
        abundances.
      </Typography>
      <Typography paragraph>
        To calculate the molar mass of a compound, one adds up the atomic masses
        of all the atoms present in a molecule, considering the number of times
        each type of atom occurs. The atomic masses are usually taken from the
        periodic table. For example, the molar mass of water (H₂O) can be
        calculated by adding the atomic masses of two hydrogen atoms and one
        oxygen atom.{" "}
      </Typography>
      <Typography paragraph>
        Molar mass is a versatile concept used in various aspects of chemistry,
        such as determining the amount of substance in a given mass (moles =
        mass/molar mass), stoichiometry calculations, and understanding the
        physical properties of gases. It is particularly valuable in
        quantitative chemistry, where precise measurements of mass are essential
        for accurate chemical analysis.
      </Typography>
      <Typography paragraph>
        In the context of chemical reactions, the concept of molar mass is
        pivotal in understanding the relationships between reactants and
        products. Stoichiometry, the study of quantitative relationships in
        chemical reactions, relies on molar masses to balance equations and
        predict the amounts of substances involved. Additionally, molar mass is
        a key factor in the ideal gas law, which describes the behavior of gases
        under varying conditions.
      </Typography>
      <Typography paragraph>
        In summary, the analysis of molar mass extends across various branches
        of chemistry, facilitating the understanding and manipulation of
        chemical substances in laboratory and theoretical settings.
      </Typography>
    </div>
  );
};

export default MolarMass;

import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay, infinite }) => {
  const [currentText, setCurrentText] = useState("");
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);

  useEffect(() => {
    let timeout;
    if (phraseIndex < text.length) {
      if (letterIndex <= text[phraseIndex].length) {
        timeout = setTimeout(() => {
          setCurrentText(
            (prevText) => prevText + text[phraseIndex][letterIndex]
          );
          setLetterIndex((letterIndex) => letterIndex + 1);
          if (letterIndex === text[phraseIndex].length) {
            setCurrentText("");
            setLetterIndex(0);
            setPhraseIndex((prev) => prev + 1);
          }
        }, delay);
        return () => clearTimeout(timeout);
      }
    } else if (infinite) {
      setPhraseIndex(0);
      setLetterIndex(0);
      setCurrentText("");
    }
  }, [text, currentText, letterIndex, phraseIndex, delay, infinite]);

  return <span>{currentText}</span>;
};

export default Typewriter;

import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const Cooling = () => {
  const [ambient, setAmbient] = useState(25);
  const [ambientUnit, setAmbientUnit] = useState("Celsius (C)");

  const [initial, setInitial] = useState(70);
  const [initialUnit, setInitialUnit] = useState("Celsius (C)");

  const [vessel, setVessel] = useState("Plastic Cup");
  const [beverage, setBeverage] = useState("Coffee");

  const [time, setTime] = useState(3);
  const [finalTime, setFinalTime] = useState("");
  const [temp, setTemp] = useState("");

  const tempUnits = ["Fahrenheit (F)", "Celsius (C)", "Kelvin (K)"];

  const convertTemp = (t, unit) => {
    if (unit === "Fahrenheit (F)") {
      return (t - 30) * 0.5;
    } else if (unit === "Kelvin (K)") {
      return +t - 273;
    }
    return t;
  };

  const heatCapacities = useMemo(() => {
    return {
      Water: 4.18,
      Coffee: 4.18,
      Tea: 4.18,
      "Hot Chocolate": 3.93,
      Milk: 3.93,
    };
  }, []);

  const heatTransferCoefficients = useMemo(() => {
    return {
      "Plastic Cup": 3.0,
      "Paper Cup": 2.5,
      "Styrofoam Cup": 1.5,
      "Ceramic Mug": 5.0,
      "Glass Mug": 7.0,
      "Stainless Steel Tumbler": 10.0,
      "Vacuum Insulated Tumbler": 0.5,
    };
  }, []);

  const surfaceAreas = useMemo(() => {
    return {
      "Plastic Cup": 0.03,
      "Paper Cup": 0.04,
      "Styrofoam Cup": 0.04,
      "Ceramic Mug": 0.06,
      "Glass Mug": 0.07,
      "Stainless Steel Tumbler": 0.08,
      "Vacuum Insulated Tumbler": 0.08,
    };
  }, []);

  useEffect(() => {
    const cAmbient = convertTemp(ambient, ambientUnit);
    const cInitial = convertTemp(initial, initialUnit);
    const heatTransferCoeff = heatTransferCoefficients[vessel];
    const surfArea = surfaceAreas[vessel];
    const heatCap = heatCapacities[beverage];
    const coolingConstant = (heatTransferCoeff * surfArea) / heatCap;
    const presentTemp =
      +cAmbient +
      (+cInitial - cAmbient) *
        Math.exp(-1 * coolingConstant * parseFloat(time));
    setTemp(presentTemp);

    const totalTime =
      Math.log(0.05 / (cInitial - cAmbient)) / (-1 * coolingConstant);
    setFinalTime(totalTime);
  }, [
    ambient,
    ambientUnit,
    initial,
    initialUnit,
    heatTransferCoefficients,
    vessel,
    surfaceAreas,
    heatCapacities,
    beverage,
    time,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Beverage Cooling</Typography>
        <Typography variant="h6">
          Investigate how quickly a hot beverage will cool off.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="cooling-input">
            <TextField
              label="Ambient Temperature"
              className="text-field"
              id="ambient"
              variant="standard"
              type="number"
              value={ambient}
              onChange={(e) => {
                setAmbient(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="ambient-unit"
              value={ambientUnit}
              variant="standard"
              onChange={(e) => {
                setAmbientUnit(e.target.value);
              }}
            >
              {tempUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="cooling-input">
            <TextField
              label="Beverage Temperature"
              className="text-field"
              id="initial"
              variant="standard"
              type="number"
              value={initial}
              onChange={(e) => {
                setInitial(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="initial-unit"
              value={initialUnit}
              variant="standard"
              onChange={(e) => {
                setInitialUnit(e.target.value);
              }}
            >
              {tempUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box>
            <FormControl>
              <FormLabel id="condition">Vessel</FormLabel>
              <Select
                className="select-field"
                id="vessel"
                value={vessel}
                variant="standard"
                onChange={(e) => {
                  setVessel(e.target.value);
                }}
              >
                {Object.keys(surfaceAreas).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <FormLabel id="condition">Beverage</FormLabel>
              <Select
                className="select-field"
                id="beverage"
                value={beverage}
                variant="standard"
                onChange={(e) => {
                  setBeverage(e.target.value);
                }}
              >
                {Object.keys(heatCapacities).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Typography variant="body">
          Total Time to Equilibrium (s):{" "}
          <span className="electro">
            {finalTime ? parseFloat(finalTime).toFixed(3) : null}
          </span>
        </Typography>

        <Typography variant="body">
          Find the temperature at any point in time: <br />
          <TextField
            className="text-field"
            label="Time elapsed (s)"
            id="initial"
            variant="standard"
            type="number"
            value={time}
            onChange={(e) => {
              setTime(e.target.value);
            }}
          />
          <TextField
            className="text-field"
            label="Temperature (C)"
            variant="standard"
            type="number"
            InputProps={{
              readOnly: true,
            }}
            value={temp ? temp.toFixed(3) : null}
          />
        </Typography>
      </Box>

      <Typography variant="h3">Beverage Cooling Explained</Typography>
      <Typography paragraph>
        The time it takes for a hot drink to reach equilibrium, or cool off, is
        influenced by various factors such as the initial temperature of the
        drink, the ambient temperature, the vessel it's in, and the heat
        capacity of the liquid inside. The process of cooling follows principles
        of heat transfer and thermodynamics, and one can use Newton's Law of
        Cooling to model this phenomenon.
      </Typography>
      <Typography paragraph>
        Newton's Law of Cooling is expressed as:
      </Typography>

      <MathJax>{"${T(t)} = {T_a} + {(T_0 - T_a)} \\cdot e^{(-kt)}$"}</MathJax>

      <Typography paragraph>
        where T(t) is the temperature of the drink at time t
      </Typography>
      <Typography paragraph>
        T<sub>a</sub> is the ambient temperature
      </Typography>
      <Typography paragraph>
        T<sub>0</sub> is the initial temperature of the drink
      </Typography>

      <Typography paragraph>
        k is a constant related to the specific cooling characteristics of the
        system
      </Typography>

      <Typography paragraph>t is time</Typography>

      <Typography paragraph>
        The time it takes for the drink to reach equilibrium can be estimated by
        finding the time t when T(t) is close to T<sub>a</sub>. This process
        involves solving the exponential equation for t. It's important to note
        that the heat capacity of the liquid inside, which influences the rate
        of temperature change, is implicitly captured in the constant k.
      </Typography>
      <Typography paragraph>
        In more complex situations, where additional factors such as the shape
        and material of the vessel or the surrounding environment's heat
        conduction are considered, more sophisticated heat transfer models may
        be needed. These can involve differential equations and considerations
        of conduction, convection, and radiation.
      </Typography>
      <Typography paragraph>
        In practical terms, when calculating the time for a hot drink to cool
        off, one may need to make simplifying assumptions or use empirical data
        to determine the constants in the cooling model. Experiments can be
        conducted to measure the rate of cooling under controlled conditions,
        and the results can be used to refine the model for more accurate
        predictions.
      </Typography>

      
    </div>
  );
};

export default Cooling;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MathJax } from "better-react-mathjax";

import * as nerdamer from "nerdamer";
require("nerdamer/Solve");
require("nerdamer/Algebra");
require("nerdamer/Solve");

const IdealGas = () => {
  const [pressure, setPressure] = useState("");
  const [pressureUnit, setPressureUnit] = useState("pascals (Pa)");

  const [vol, setVol] = useState("");
  const [volUnit, setVolUnit] = useState("cubic meters");

  const [moles, setMoles] = useState("");

  const [temp, setTemp] = useState("");
  const [tempUnit, setTempUnit] = useState("Kelvin (K)");

  const [missingVar, setMissingVar] = useState(null);

  const [calcDone, setCalcDone] = useState(false);
  const [missingVal, setMissingVal] = useState(null);

  const convertPressure = (p, unit) => {
    if (unit === "pounds per square inch (psi)") {
      return p * 6895;
    } else if (unit === "standard atmospheres (atm)") {
      return p * 101300;
    } else if (unit === "inches of mercury (inHg)") {
      return p * 3386;
    }
    return p;
  };

  const convertVol = (v, unit) => {
    if (unit === "cubic feet") {
      return v / 35.315;
    } else if (unit === "cubic inches") {
      return v / 61020;
    } else if (unit === "US gallons") {
      return v / 264.2;
    }
    return v;
  };

  const convertTemp = (t, unit) => {
    if (unit === "Fahrenheit (F)") {
      return (+5 / 9) * t + 459.67;
    } else if (unit === "Celsius (C)") {
      return +t + 273;
    }
    return t;
  };

  const pressureUnits = [
    "pascals (Pa)",
    "pounds per square inch (psi)",
    "standard atmospheres (atm)",
    "inches of mercury (inHg)",
  ];

  const volumeUnits = [
    "cubic meters",
    "cubic feet",
    "cubic inches",
    "US gallons",
  ];

  const tempUnits = ["Fahrenheit (F)", "Celsius (C)", "Kelvin (K)"];

  const getNameDesc = {
    cPressure: "pressure (Pa) of the gas",
    cVol: "volume (cubic meters) of the gas",
    moles: "amount of the gas in moles",
    cTemp: "temperature (degrees Kelvin) of the gas",
  };

  useEffect(() => {
    const calculateGasEquation = () => {
      if (!calcDone) {
        const cPressure = convertPressure(pressure, pressureUnit);
        const cVol = convertVol(vol, volUnit);
        const cTemp = convertTemp(temp, tempUnit);

        const allVariables = {
          cPressure,
          cVol,
          moles,
          cTemp,
        };

        const index = Object.values(allVariables).findIndex(
          (item) => item === ""
        );
        const key = Object.keys(allVariables)[index];
        setMissingVar(key);

        const missingValue = nerdamer.solveEquations(
          "cPressure * cVol = moles * 8.31446261815324 * cTemp",
          key
        );
        console.log(missingValue.toString());
        const expression1 = nerdamer(missingValue.toString(), allVariables);
        const num = parseFloat(expression1.text()).toFixed(3);
        setMissingVal(num);
        return num;
      }
    };

    const variables = [pressure, vol, moles, temp];
    const numberEmptyVars = variables.filter((item) => item === "");
    console.log(numberEmptyVars);
    if (numberEmptyVars.length === 1) {
      setCalcDone(true);
      calculateGasEquation();
    }
  }, [pressure, vol, temp, moles, pressureUnit, volUnit, tempUnit, calcDone]);

  const resetForm = () => {
    setCalcDone(false);
    setPressure("");
    setVol("");
    setMoles("");
    setTemp("");
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Ideal Gas</Typography>
        <Typography variant="h6">
          Analyze a gas that satisfies the conditions for ideality (large number
          of molecules, point particle molecules, non-interacting molecules,
          perfectly elastic collisions, compliant with Newton's laws.)
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="gas-input">
            <TextField
              label="Pressure"
              className="text-field"
              id="press"
              variant="standard"
              type="number"
              value={pressure}
              onChange={(e) => {
                setPressure(e.target.value);
                setCalcDone(false);
              }}
            />
            <Select
              className="select-field"
              id="press-unit"
              value={pressureUnit}
              variant="standard"
              onChange={(e) => {
                setPressureUnit(e.target.value);
                setCalcDone(false);
              }}
            >
              {pressureUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="gas-input">
            <TextField
              label="Volume"
              className="text-field"
              id="vol"
              variant="standard"
              type="number"
              value={vol}
              onChange={(e) => {
                setVol(e.target.value);
                setCalcDone(false);
              }}
            />
            <Select
              className="select-field"
              id="vol-unit"
              value={volUnit}
              variant="standard"
              onChange={(e) => {
                setVolUnit(e.target.value);
                setCalcDone(false);
              }}
            >
              {volumeUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="gas-input">
            <TextField
              label="Quantity"
              className="text-field"
              id="moles"
              variant="standard"
              type="number"
              value={moles}
              onChange={(e) => {
                setMoles(e.target.value);
                setCalcDone(false);
              }}
            />
            <Select
              className="select-field"
              variant="standard"
              defaultValue="1"
              disabled
            >
              <MenuItem selected="true" value="1">
                moles (mol)
              </MenuItem>
            </Select>
          </Box>

          <Box className="gas-input">
            <TextField
              label="Temperature"
              className="text-field"
              id="temp"
              variant="standard"
              type="number"
              value={temp}
              onChange={(e) => {
                setTemp(e.target.value);
                setCalcDone(false);
              }}
            />
            <Select
              className="select-field"
              id="temp-unit"
              value={tempUnit}
              variant="standard"
              onChange={(e) => {
                setTempUnit(e.target.value);
                setCalcDone(false);
              }}
            >
              {tempUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        {calcDone ? (
          <Typography variant="body">
            The missing variable is {getNameDesc[missingVar]}:{" "}
            <span className="electro">{missingVal}</span>
            <Button
              variant="outlined"
              sx={{ display: "block" }}
              onClick={resetForm}
            >
              Reset
            </Button>
          </Typography>
        ) : null}
      </Box>
      <Typography variant="h3">Ideal Gas Explained</Typography>
      <Typography paragraph>
        Performing an ideal gas analysis involves using the ideal gas law, which
        describes the behavior of an ideal gas under various conditions. The
        ideal gas law is typically expressed as
      </Typography>
      <MathJax>{"$ {PV = nRT}$"}</MathJax>
      <Typography>where</Typography>
      <Typography>P is the pressure of the gas</Typography>
      <Typography>V is the volume it occupies</Typography>
      <Typography>n is the number of moles of gas</Typography>
      <Typography>R is the ideal gas constant</Typography>
      <Typography>
        T is the temperature of the gas measured in Kelvin
      </Typography>

      <Typography paragraph>
        This equation allows you to calculate any of the four variables (P, V, n
        or T) if the other three are known.
      </Typography>

      <Typography paragraph>
        It's important to note that when using the ideal gas law, the units must
        be consistent. Pressure is typically measured in pascals (Pa), volume in
        cubic meters (m³), temperature in Kelvin (K), and the number of moles is
        a dimensionless quantity.
      </Typography>
      <Typography paragraph>
        This ideal gas analysis is particularly useful in various scientific and
        engineering applications, such as in chemistry labs, where the behavior
        of gases is crucial for understanding chemical reactions. Additionally,
        it's widely applied in fields like thermodynamics and fluid mechanics
        for modeling and analyzing the behavior of gases under different
        conditions.
      </Typography>
    </div>
  );
};

export default IdealGas;

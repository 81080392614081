import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { MathJax } from "better-react-mathjax";

const StoppingDistance = () => {
  const [speed, setSpeed] = useState(50);
  const [speedUnit, setSpeedUnit] = useState("kilometers per hour (km/h)");
  const [reactionTime, setReactionTime] = useState(1.5);
  const [reactionUnit, setReactionUnit] = useState(1.5);
  const [grade, setGrade] = useState(5);
  const [gradeDir, setGradeDir] = useState(1);
  const [condition, setCondition] = useState(0.7);
  const [customTime, setCustomTime] = useState(true);
  const [stopDistance, setStopDistance] = useState(null);

  const speedUnits = [
    "miles per hour (mph)",
    "meters per second (m/s)",
    "kilometers per hour (km/h)",
    "feet per second (ft/s)",
  ];

  const convertSpeed = (speed, unit) => {
    if (unit === "miles per hour (mph)") {
      return speed * 1.609;
    } else if (unit === "feet per second (ft/s)") {
      return speed * 1.097;
    } else if (unit === "meters per second (m/s)") {
      return speed * 3.6;
    }
    return speed;
  };

  useEffect(() => {
    const convertedSpeed = convertSpeed(speed, speedUnit);
    const dist =
      0.278 * reactionTime * convertedSpeed +
      convertedSpeed ** 2 / (254 * ((+grade / 100) * +gradeDir + +condition));
    setStopDistance(dist.toFixed(3));
  }, [grade, speed, condition, gradeDir, reactionTime, speedUnit]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Stopping Distance</Typography>
        <Typography variant="h6">
          See how stopping distance varies under different conditions.{" "}
        </Typography>
        <Typography variant="body">
          This calculator uses the stopping distance formula provided by AASHTO
          (the American Association of State Highway and Transportation
          Officials).
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="stopping-distance-input">
            <TextField
              label="Speed"
              className="text-field"
              id="speed"
              variant="standard"
              type="number"
              defaultValue={speed}
              onChange={(e) => setSpeed(e.target.value)}
            />
            <Select
              className="select-field"
              id="weight-unit"
              value={speedUnit}
              variant="standard"
              onChange={(e) => {
                setSpeedUnit(e.target.value);
              }}
            >
              {speedUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="stopping-distance-input">
            <TextField
              label="Reaction Time (s)"
              className="text-field"
              id="reaction-time"
              variant="standard"
              type="number"
              disabled={customTime}
              value={reactionTime}
              onChange={(e) => setReactionTime(e.target.value)}
            />
            <Select
              className="select-field"
              id="reaction-unit"
              value={reactionUnit}
              defaultValue="average driver (2s)"
              variant="standard"
              onChange={(e) => {
                setReactionUnit(e.target.value);
                setReactionTime(e.target.value);
                if (e.target.value === "5") {
                  setCustomTime(false);
                } else {
                  setCustomTime(true);
                }
              }}
            >
              <MenuItem value="0.8">alert driver (0.8s)</MenuItem>
              <MenuItem value="1.5">average driver (1.5s)</MenuItem>

              <MenuItem value="2">elderly driver (2s)</MenuItem>
              <MenuItem value="2.5">intoxicated driver (2.5s)</MenuItem>
              <MenuItem value="5">custom time</MenuItem>
            </Select>
          </Box>

          <Box className="stopping-distance-input">
            <TextField
              label="Grade (%)"
              className="text-field"
              id="grade"
              variant="standard"
              type="number"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
            />
            <Select
              className="select-field"
              id="grade-dir"
              value={gradeDir}
              defaultValue="downhill"
              variant="standard"
              onChange={(e) => {
                setGradeDir(e.target.value);
              }}
            >
              <MenuItem value="1">uphill</MenuItem>
              <MenuItem value="-1">downhill</MenuItem>
            </Select>
          </Box>

          <FormControl>
            <FormLabel id="condition">Road Conditions</FormLabel>
            <RadioGroup
              row
              aria-labelledby="condition"
              type="number"
              value={condition}
              name="condition"
              onChange={(e) => setCondition(e.target.value)}
            >
              <FormControlLabel
                type="number"
                defaultChecked
                value="0.7"
                control={<Radio />}
                label="Dry"
              />
              <FormControlLabel
                type="number"
                value="0.3"
                control={<Radio />}
                label="Wet"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Typography variant="body">
          Stopping Distance (m):{" "}
          <span className="electro">{stopDistance ? stopDistance : null}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Stopping Distance Explained</Typography>
      <Typography paragraph>
        The stopping distance of a vehicle is a crucial factor in road safety
        and is influenced by various parameters, including speed, road
        conditions (wet or dry), driver reaction time, and road grade or slope.
        Understanding how these factors interact is essential for designing
        roadways, establishing speed limits, and promoting safe driving
        practices.
      </Typography>
      <Typography paragraph>
        The stopping distance of a vehicle consists of two main components: the
        thinking distance (also known as the reaction time) and the braking
        distance. The thinking distance is the distance traveled by the vehicle
        during the driver's reaction time before initiating the braking action.
        This time is influenced by the driver's attentiveness, alertness, and
        ability to react promptly to a situation. The braking distance is the
        distance the vehicle travels while coming to a complete stop after the
        brakes are applied.
      </Typography>
      <Typography paragraph>
        According to the the American Association of State Highway and
        Transportation Officials, the stopping distance can be calculated with
        the following equation
      </Typography>

      <MathJax>
        {"${S} = 0.278 \\cdot t \\cdot v  + {v^2 \\over {254  (f + G)} }$"}
      </MathJax>
      <Typography>where</Typography>
      <Typography>S is stopping distance in meters</Typography>

      <Typography>t is the perception reaction time in seconds</Typography>
      <Typography>v is the speed of the car in km/hr</Typography>
      <Typography>G is the grade (slope) of the road</Typography>

      <Typography>
        f is the coefficient of friction between the tires and the road (it is
        assumed to be 0.7 on a dry road and 0.3-0.4 on a wet road)
      </Typography>

      <Typography paragraph>
        It's important to note that the coefficient of friction varies between
        dry and wet road conditions, significantly affecting the braking
        distance. In wet conditions, the presence of water on the road reduces
        the friction between the tires and the road, resulting in a longer
        braking distance.
      </Typography>
      <Typography paragraph>
        In summary, the calculation of stopping distance involves considering
        both the reaction time and the braking distance. The interplay of
        factors such as speed, driver reaction time, road conditions, and road
        grade highlights the importance of these variables in road safety and
        underscores the need for responsible driving practices and
        well-maintained roadways.
      </Typography>
    </div>
  );
};

export default StoppingDistance;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MathJax } from "better-react-mathjax";

import * as nerdamer from "nerdamer";
require("nerdamer/Solve");
require("nerdamer/Algebra");
require("nerdamer/Solve");

const Momentum = () => {
  const [mass1, setMass1] = useState("");
  const [mass1Unit, setMass1Unit] = useState("kilograms (kg)");
  const [mass2, setMass2] = useState("");
  const [mass2Unit, setMass2Unit] = useState("kilograms (kg)");

  const [initVelo1, setInitVelo1] = useState("");
  const [initVelo1Unit, setInitVelo1Unit] = useState("meters per second (m/s)");
  const [initVelo2, setInitVelo2] = useState("");
  const [initVelo2Unit, setInitVelo2Unit] = useState("meters per second (m/s)");

  const [finVelo1, setFinVelo1] = useState("");
  const [finVelo1Unit, setFinVelo1Unit] = useState("meters per second (m/s)");
  const [finVelo2, setFinVelo2] = useState("");
  const [finVelo2Unit, setFinVelo2Unit] = useState("meters per second (m/s)");

  const [calcDone, setCalcDone] = useState(false);

  const massUnits = [
    "milligrams (mg)",
    "grams (g)",
    "kilograms (kg)",
    "ounces (oz)",
    "pounds (lb)",
  ];
  const speedUnits = [
    "meters per second (m/s)",
    "kilometers per hour (km/h)",
    "feet per second (ft/s)",
  ];

  const allVariables = useMemo(() => {
    return {
      mass1,
      initVelo1,
      finVelo1,
      mass2,
      initVelo2,
      finVelo2,
    };
  }, [mass1, initVelo1, finVelo1, mass2, initVelo2, finVelo2]);

  const convertSpeed = (speed, unit) => {
    if (unit === "kilometers per hour (km/h)") {
      return speed / 3.6;
    } else if (unit === "feet per second (ft/s)") {
      return speed / 3.281;
    }
    return speed;
  };

  const convertMass = (mass, unit) => {
    if (unit === "grams (g)") {
      return mass / 1000;
    } else if (unit === "milligrams (mg)") {
      return mass / 1000000;
    } else if (unit === "ounces (oz)") {
      return mass / 35.274;
    } else if (unit === "pounds (lb)") {
      return mass / 2.205;
    } else if (unit === "kilograms (kg)") {
      return mass;
    }
  };

  const findMissingVar = () => {
    const index = Object.values(allVariables).findIndex((item) => item === "");
    const key = Object.keys(allVariables)[index];
    return key;
  };

  const calculateMomentum = useCallback(() => {
    const key = findMissingVar();
    const missingValue = nerdamer.solveEquations(
      "mass1 * initVelo1 + mass2 * initVelo2 = mass1 * finVelo1 + mass2 * finVelo2",
      key
    );
    const expression1 = nerdamer(missingValue.toString(), allVariables);
    const num = parseFloat(expression1.text()).toFixed(3);
    return num;
  }, [allVariables]);

  useEffect(() => {
    const variables = [mass1, mass2, initVelo1, initVelo2, finVelo1, finVelo2];
    const numberEmptyVars = variables.filter((item) => item === "");
    if (numberEmptyVars.length === 1 || numberEmptyVars.length === 0) {
      calculateMomentum();
      setCalcDone(true);
    }
  }, [
    mass1,
    mass2,
    mass1Unit,
    mass2Unit,
    initVelo1,
    initVelo2,
    finVelo1,
    finVelo2,
    calculateMomentum,
  ]);

  const getVariableDescription = {
    mass1: "Mass of Object 1 (kg)",
    mass2: "Mass of Object 2 (kg)",
    initVelo1: "Initial Velocity of Object 1 (m/s)",
    initVelo2: "Initial Velocity of Object 2 (m/s)",
    finVelo1: "Final Velocity of Object 1 (m/s)",
    finVelo2: "Final Velocity of Object 2 (m/s)",
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Conservation of Momentum </Typography>
        <Typography variant="h6">
          How does the conservation of linear momentum help us solve physics
          problems?
        </Typography>
        <Typography variant="body">
          There are 6 variables. Once you enter any 5 values, the calculator
          will determine the final value.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Typography
            variant="body"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Object 1
          </Typography>
          <Box className="momentum-input">
            <TextField
              label="Mass"
              className="text-field"
              id="massOne"
              variant="standard"
              type="number"
              defaultValue={mass1}
              onChange={(e) => setMass1(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="kilograms (kg)"
              variant="standard"
              onChange={(e) => {
                setMass1Unit(e.target.value);

                setMass1(
                  convertMass(
                    document.getElementById("massOne").value,
                    e.target.value
                  )
                );
              }}
            >
              {massUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box className="momentum-input">
            <TextField
              label="Initial Velocity"
              className="text-field"
              id="initVelOne"
              variant="standard"
              type="number"
              defaultValue={initVelo1}
              onChange={(e) => setInitVelo1(e.target.value)}
            />
            <Select
              className="select-field"
              value={initVelo1Unit}
              variant="standard"
              onChange={(e) => {
                setInitVelo1Unit(e.target.value);
                setInitVelo1(
                  convertSpeed(
                    document.getElementById("initVelOne").value,
                    e.target.value
                  )
                );
              }}
            >
              {speedUnits.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box className="momentum-input">
            <TextField
              label="Final Velocity"
              className="text-field"
              id="finVelOne"
              variant="standard"
              type="number"
              defaultValue={finVelo1}
              onChange={(e) => setFinVelo1(e.target.value)}
            />
            <Select
              className="select-field"
              value={finVelo1Unit}
              variant="standard"
              onChange={(e) => {
                setFinVelo1Unit(e.target.value);
                setFinVelo1(
                  convertSpeed(
                    document.getElementById("finVelOne").value,
                    e.target.value
                  )
                );
              }}
            >
              {speedUnits.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Typography
            variant="body"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Object 2
          </Typography>
          <Box className="momentum-input">
            <TextField
              label="Mass"
              id="massTwo"
              className="text-field"
              variant="standard"
              type="number"
              defaultValue={mass2}
              onChange={(e) => setMass2(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="kilograms (kg)"
              variant="standard"
              onChange={(e) => {
                setMass2Unit(e.target.value);
                setMass2(
                  convertMass(
                    document.getElementById("massTwo").value,
                    e.target.value
                  )
                );
              }}
            >
              {massUnits.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box className="momentum-input">
            <TextField
              label="Initial Velocity"
              className="text-field"
              id="initVelTwo"
              variant="standard"
              type="number"
              defaultValue={initVelo2}
              onChange={(e) => setInitVelo2(e.target.value)}
            />
            <Select
              className="select-field"
              value={initVelo2Unit}
              variant="standard"
              onChange={(e) => {
                setInitVelo2Unit(e.target.value);
                setInitVelo2(
                  convertSpeed(
                    document.getElementById("initVelTwo").value,
                    e.target.value
                  )
                );
              }}
            >
              {speedUnits.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box className="momentum-input">
            <TextField
              label="Final Velocity"
              className="text-field"
              id="finVelTwo"
              variant="standard"
              type="number"
              defaultValue={finVelo2}
              onChange={(e) => setFinVelo2(e.target.value)}
            />
            <Select
              className="select-field"
              value={finVelo2Unit}
              variant="standard"
              onChange={(e) => {
                setFinVelo2Unit(e.target.value);
                setFinVelo2(
                  convertSpeed(
                    document.getElementById("finVelTwo").value,
                    e.target.value
                  )
                );
              }}
            >
              {speedUnits.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Box>
        </Box>
        {calcDone ? (
          <Typography variant="body">
            {getVariableDescription[findMissingVar()]}:{" "}
            <span className="electro">{calculateMomentum()}</span>
            <Button
              variant="outlined"
              sx={{ display: "block" }}
              onClick={() => window.location.reload()}
            >
              Reset
            </Button>
          </Typography>
        ) : null}
      </Box>

      <Typography variant="h3">Momentum Explained</Typography>
      <Typography paragraph>
        The linear conservation of momentum is a fundamental principle in
        classical mechanics that states that the total linear momentum of an
        isolated system remains constant if no external forces act on it. Linear
        momentum is the product of an object's mass and its velocity and is a
        vector quantity, meaning it has both magnitude and direction.
        Mathematically, the conservation of linear momentum can be expressed as:
      </Typography>

      <Typography style={{ alignSelf: "flex-start", fontSize: 20 }}>
        Momentum<sub>initial</sub> = Momentum<sub>final</sub>
      </Typography>
      <Typography paragraph>
        This principle holds true for systems of interacting particles or
        objects, where the net external force acting on the system is zero. In
        such cases, the total linear momentum before a collision or interaction
        is equal to the total linear momentum after the event.
      </Typography>
      <Typography paragraph>
        The linear conservation of momentum is a powerful tool in solving
        physics problems, particularly those involving collisions or explosions.
        When dealing with a system of particles, the sum of the individual
        momenta before an event is equal to the sum of the individual momenta
        after the event. This principle can be applied to solve for unknown
        variables such as velocities or masses.
      </Typography>
      <Typography paragraph>
        In the context of collisions, a partially elastic collision is one in
        which some kinetic energy is conserved while some is lost during the
        collision. When objects collide, momentum is always conserved according
        to the principle of conservation of momentum, which states that the
        total momentum of a closed system remains constant if no external forces
        act on it. However, in an elastic collision, not only is momentum
        conserved, but kinetic energy is also conserved. In contrast, in a
        partially elastic collision, momentum is conserved, but some kinetic
        energy is transformed into other forms of energy, such as heat or
        deformation of the colliding objects.
      </Typography>
      <Typography paragraph>
        Mathematically, in a partially elastic collision, the total kinetic
        energy of the system before the collision may not equal the total
        kinetic energy after the collision. Some of the initial kinetic energy
        is converted into other forms during the collision process. However,
        momentum is still conserved, meaning the total momentum of the system
        before and after the collision remains the same.
      </Typography>
      <Typography paragraph>
        For example, when two cars collide on a road, some of the initial
        kinetic energy of the cars is converted into sound, deformation of the
        cars, and heat due to friction. The momentum of the cars before the
        collision is equal to the momentum of the cars after the collision, but
        the total kinetic energy of the system decreases due to the conversion
        of kinetic energy into other forms. This is why partially elastic
        collisions are often described as having "less bounce" than elastic
        collisions, as some of the kinetic energy is lost in the process. The
        conservation of momentum analysis can be written as
      </Typography>
      <MathJax>
        {"${m_1 u_{1i} + m_2 u_{2i} = m_1 u_{1f} + m_2 u_{2f}}$"}
      </MathJax>
      <Typography>
        Here, m<sub>1</sub> and m<sub>2</sub> are the masses of the two objects.
        u<sub>1i</sub> and u<sub>2i</sub> are their initial velocities, while u
        <sub>1f</sub> and u<sub>2f</sub> are their final velocities.
      </Typography>
      <Typography paragraph>
        The linear conservation of momentum is a powerful tool in physics
        problem-solving, providing a systematic and quantitative approach to
        understanding the outcomes of collisions and interactions between
        objects. It is widely used in fields such as mechanics, astrophysics,
        and engineering to analyze and predict the behavior of systems with
        multiple interacting components.
      </Typography>
    </div>
  );
};

export default Momentum;

import React from "react";
import Typography from "@mui/material/Typography";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AllConstants from "../data/AllConstants";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const Constants = () => {
  const displayConstants = () => {
    return AllConstants.map((item, i) => {
      return (
        <Card sx={{ minWidth: 275, mx: "4px", padding: "10px" }} key={i}>
          <Typography
            style={{
              fontWeight: "bold",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LightbulbIcon fontSize="10" /> {item.name}
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              alignSelf: "flex-start",
              textAlign: "left",
            }}
          >
            Value: {Number(item.value).toExponential()} {item.unit}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Usage: {item.description}
          </Typography>
        </Card>
      );
    });
  };

  return (
    <div>
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        Engineering & Mathematical Constants
      </Typography>
      <Box className="constants" style={{ display: "grid", gap: "10px" }}>
        {displayConstants()}
      </Box>
    </div>
  );
};

export default Constants;

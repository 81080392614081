import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const HeatCapacity = () => {
  const [mass, setMass] = useState(10);
  const [massUnit, setMassUnit] = useState("pounds (lb)");
  const [material, setMaterial] = useState("Water");
  const [heatCapacity, setHeatCapacity] = useState(null);

  const specificHeat = useMemo(() => {
    return {
      Water: 1.0,
      Air: 0.24,
      Aluminum: 0.22,
      Copper: 0.092,
      Iron: 0.11,
      Lead: 0.031,
      Mercury: 0.033,
      Gold: 0.032,
      Silver: 0.056,
      Ethanol: 0.59,
      Glycerol: 0.57,
      Methanol: 0.61,
      Ammonia: 0.52,
      Hydrogen: 3.41,
      Helium: 1.24,
      "Carbon Dioxide": 0.217,
      Oxygen: 0.218,
      Nitrogen: 0.248,
    };
  }, []);
  const massUnits = ["pounds (lb)", "grams (g)", "kilograms (kg)"];

  const convertMass = (mass, unit) => {
    if (unit === "grams (g)") {
      return mass / 453.6;
    } else if (unit === "kilograms (kg)") {
      return mass * 2.205;
    }
    return mass;
  };

  useEffect(() => {
    const convertedMass = convertMass(mass, massUnit);
    const heatCap = specificHeat[material] * convertedMass;
    setHeatCapacity(heatCap.toFixed(3));
  }, [mass, massUnit, specificHeat, material]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Heat Capacity</Typography>
        <Typography variant="h6">
          Determine the ability of a body to absorb heat.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="heat-cap-input">
            <TextField
              label="Mass of Object"
              className="text-field"
              id="objMass"
              variant="standard"
              type="number"
              defaultValue={mass}
              onChange={(e) => setMass(e.target.value)}
            />
            <Select
              className="select-field"
              id="mass-unit"
              value={massUnit}
              variant="standard"
              onChange={(e) => {
                setMassUnit(e.target.value);
              }}
            >
              {massUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="heat-cap-input">
            <FormControl>
              <FormLabel id="condition">Material</FormLabel>
              <Select
                className="select-field"
                style={{ justifyContent: "flex-end" }}
                id="material"
                value={material}
                variant="standard"
                onChange={(e) => {
                  console.log(e.target.value);
                  setMaterial(e.target.value);
                }}
              >
                {Object.keys(specificHeat).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>{" "}
            </FormControl>
          </Box>
        </Box>
        <Typography variant="body">
          Specific Heat (Btu/(lb*F)):{" "}
          <span className="electro">{specificHeat[material]}</span>
        </Typography>
        <Typography variant="body">
          Heat Capacity (Btu/(F)):{" "}
          <span className="electro">{heatCapacity}</span>
        </Typography>
      </Box>
      <Typography variant="h3">Heat Capacity Explained</Typography>
      <Typography paragraph>
        Heat capacity is a fundamental thermodynamic property that quantifies
        the amount of heat energy required to raise the temperature of a
        substance by a certain amount. The heat capacity C of a material can be
        calculated based on its mass m and the specific heat C<sub>s</sub>
        of the material.
      </Typography>
      <Typography paragraph>
        The formula for calculating heat capacity is given by:
      </Typography>

      <MathJax>{"${C = m C_s}$"}</MathJax>

      <Typography>where</Typography>
      <Typography>C is the heat capacity</Typography>

      <Typography>m is the mass of the material</Typography>
      <Typography>
        C<sub>s</sub> is the specific heat of the material
      </Typography>

      <Typography paragraph>
        The unit of heat capacity is typically expressed in joules per degree
        Celsius (J/°C) in the International System of Units (SI). This means
        that the heat capacity tells us how much heat energy is needed to raise
        the temperature of the substance by one degree Celsius.
      </Typography>
      <Typography paragraph>
        The specific heat C<sub>s</sub> is a material-specific property
        representing the amount of heat required to raise the temperature of one
        unit mass of the substance by one degree Celsius. It is typically
        measured in joules per gram per degree Celsius (J/g°C) or in calories
        per gram per degree Celsius (cal/g°C). Different materials have
        different specific heat values, and these values are crucial in
        understanding the thermal behavior of substances.
      </Typography>
      <Typography paragraph>
        The heat capacity calculation is particularly useful in various
        practical applications, such as in designing heating or cooling systems,
        assessing thermal energy storage, and understanding the temperature
        changes in chemical reactions. It's important to note that heat capacity
        is an extensive property, meaning it depends on the amount of material
        present. Consequently, the larger the mass of the substance, the greater
        the heat capacity required to cause a certain temperature change.
      </Typography>
      <Typography paragraph>
        In summary, calculating heat capacity based on mass and specific heat
        provides a quantitative measure of how much thermal energy is needed to
        change the temperature of a given amount of material. This information
        is valuable in a wide range of scientific and engineering contexts where
        heat transfer and temperature changes are critical considerations.
      </Typography>
    </div>
  );
};

export default HeatCapacity;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MathJax } from "better-react-mathjax";

const FreeFall = () => {
  const gravity = 9.80665;

  const [initVel, setInitVel] = useState(null);
  const [initVelUnit, setInitVelUnit] = useState("meters per second (m/s)");

  const [height, setHeight] = useState(null);
  const [heightUnit, setHeightUnit] = useState("meters (m)");

  const [time, setTime] = useState(null);
  const [timeUnit, setTimeUnit] = useState("seconds (s)");

  const [vel, setVel] = useState(null);
  const [velUnit, setVelUnit] = useState("meters per second (m/s)");
  const [calcDone, setCalcDone] = useState(false);

  const speedUnits = [
    "meters per second (m/s)",
    "feet per second (ft/s)",
    "kilometers per hour (km/h)",
  ];

  const timeUnits = ["seconds (s)", "minutes (min)", "hours (hrs)"];

  const distanceUnits = ["meters (m)", "feet (ft)", "miles (mi)"];

  const convertSpeed = (speed, unit) => {
    if (unit === "kilometers per hour (km/h)") {
      return speed / 3.6;
    } else if (unit === "feet per second (ft/s)") {
      return speed / 3.281;
    }
    return speed;
  };

  const convertTime = (time, unit) => {
    if (unit === "minutes (min)") {
      return time * 60;
    } else if (unit === "hours (hr)") {
      return time * 3600;
    }
    return time;
  };

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "miles (mi)") {
      return dist * 1609;
    }
    return dist;
  };

  const calculateFromHeight = (h) => {
    const cInitVel = convertSpeed(initVel, initVelUnit);
    const timePassed =
      (-cInitVel + Math.sqrt(initVel ** 2 + 2 * gravity * h)) / gravity;
    setTime(timePassed);
    const velFinal = +cInitVel + gravity * timePassed;
    setVel(velFinal);
    setCalcDone(true);
  };

  const calculateFromTime = (t) => {
    const cInitVel = convertSpeed(initVel, initVelUnit);

    const velFinal = +cInitVel + gravity * t;
    setVel(velFinal);
    const distTraveled = +cInitVel * t + 0.5 * gravity * t ** 2;
    setHeight(distTraveled);
    setCalcDone(true);
  };

  const calculateFromVel = (v) => {
    const cInitVel = convertSpeed(initVel, initVelUnit);

    const timePassed = (v - cInitVel) / gravity;
    setTime(timePassed);
    const distTraveled = +cInitVel * time + 0.5 * gravity * time ** 2;
    setHeight(distTraveled);
    setCalcDone(true);
  };

  const clearFields = () => {
    window.location.reload();
  };

  useEffect(() => {
    const convertedHeight = convertDistance(height, heightUnit);
    const convertedTime = convertTime(time, timeUnit);
    const convertedVelocity = convertSpeed(vel, velUnit);

    if (document.querySelector("#height").value) {
      calculateFromHeight(convertedHeight);
      document.querySelector("#time").disabled = true;
      document.querySelector("#velocity").disabled = true;
    } else if (document.querySelector("#time").value) {
      calculateFromTime(convertedTime);
      document.querySelector("#height").disabled = true;
      document.querySelector("#velocity").disabled = true;
    } else if (document.querySelector("#velocity").value) {
      calculateFromVel(convertedVelocity);
      document.querySelector("#height").disabled = true;
      document.querySelector("#time").disabled = true;
    }
  });

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Free Fall</Typography>
        <Typography variant="h6">
          How does an object move under the force of gravitational acceleration?
        </Typography>
        <Typography variant="body">
          Enter any one of the three required variables (height, time of fall or
          final velocity) and the calculator will automatically calculate the
          remaining variables.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="free-fall-input">
            <TextField
              label="Initial Velocity"
              className="text-field"
              id="init-velocity"
              variant="standard"
              type="number"
              defaultValue={initVel}
              onChange={(e) => setInitVel(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="meters per second (m/s)"
              variant="standard"
              onChange={(e) => {
                setInitVelUnit(e.target.value);
              }}
            >
              {speedUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <hr />
          <Box className="free-fall-input">
            <TextField
              label="Height"
              className="text-field"
              id="height"
              variant="standard"
              type="number"
              defaultValue={height}
              onChange={(e) => setHeight(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="meters (m)"
              variant="standard"
              onChange={(e) => {
                setHeightUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="free-fall-input">
            <TextField
              label="Time of Fall"
              className="text-field"
              id="time"
              variant="standard"
              type="number"
              defaultValue={time}
              onChange={(e) => setTime(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="seconds (s)"
              variant="standard"
              onChange={(e) => {
                setTimeUnit(e.target.value);
              }}
            >
              {timeUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="free-fall-input">
            <TextField
              label="Final Velocity"
              className="text-field"
              id="velocity"
              variant="standard"
              type="number"
              defaultValue={vel}
              onChange={(e) => setVel(e.target.value)}
            />
            <Select
              className="select-field"
              defaultValue="meters per second (m/s)"
              variant="standard"
              onChange={(e) => {
                setVelUnit(e.target.value);
              }}
            >
              {speedUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        {calcDone ? (
          <div>
            <Typography variant="body1">
              Time Elapsed (s):{" "}
              <span className="electro">
                {parseFloat(convertTime(time, timeUnit)).toFixed(3)}
              </span>
            </Typography>
            <Typography variant="body1">
              Distance Dropped (m):{" "}
              <span className="electro">
                {parseFloat(convertDistance(height, heightUnit)).toFixed(3)}
              </span>
            </Typography>
            <Typography variant="body1">
              Final Velocity (m/s):{" "}
              <span className="electro">
                {parseFloat(convertSpeed(vel, velUnit)).toFixed(3)}
              </span>
            </Typography>
            <Button
              variant="outlined"
              sx={{ display: "block" }}
              onClick={clearFields}
            >
              Reset
            </Button>
          </div>
        ) : null}
      </Box>
      <Typography variant="h3">Free Fall Explained</Typography>
      <Typography paragraph>
        In the context of free fall, an object is falling under the influence of
        gravity with no other forces acting on it except for gravity. The motion
        of an object in free fall can be described using several kinematic
        equations that relate its position, velocity, time, and acceleration.
        Three key parameters often of interest are time, speed, and height, and
        they can be calculated using these equations.
      </Typography>
      <Typography paragraph>
        The equation that describes the vertical motion of an object in free
        fall is:
      </Typography>

      <MathJax>{"${h(t)} = {h_0} + {v_0}{t} - {1 \\over 2} gt^2$"}</MathJax>

      <Typography paragraph>where</Typography>
      <Typography paragraph>
        h(t) is the height of the object at time t
      </Typography>

      <Typography paragraph>
        h<sub>0</sub> is the initial height of the object
      </Typography>
      <Typography paragraph>
        v<sub>0</sub> is the initial velocity of the object
      </Typography>

      <Typography paragraph>
        g is the acceleration due to gravity (approximately 9.8 m/s<sup>2</sup>){" "}
      </Typography>
      <Typography>t is the time elapsed</Typography>

      <Typography paragraph>
        To calculate the time of flight, one might use the quadratic formula to
        solve for t when h(t) = 0. Depending on the problem, there may be two
        solutions (corresponding to the ascent and descent) or just one solution
        (if the object is dropped).
      </Typography>
      <Typography paragraph>
        To determine the final velocity v<sub>f</sub> at a certain time, the
        equation is:
      </Typography>
      <MathJax>{"${v_f} = {v_0} - {gt}$"}</MathJax>

      <Typography paragraph>
        The speed of the object will be the magnitude of the final velocity.
      </Typography>
      <Typography paragraph>
        The height at a specific time t can be found using the first equation by
        plugging in the given values for initial height, initial velocity,
        gravitational acceleration and time elapsed.
      </Typography>
      <Typography paragraph>
        These equations provide a comprehensive framework for analyzing the
        motion of an object in free fall. It is important to be consistent with
        units (e.g., meters for height, seconds for time) when applying these
        equations. Additionally, if the initial velocity is zero, certain terms
        in the equations simplify, making the calculations more straightforward.
        Free fall problems are commonly encountered in physics, and these
        equations offer a systematic way to analyze and solve them.
      </Typography>
    </div>
  );
};
export default FreeFall;

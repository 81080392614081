import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { Box } from "@mui/material";
import OutboundIcon from "@mui/icons-material/Outbound";

const DisplayCard = ({ calc }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        minWidth: 120,
      }}
      className="card"
      onClick={() => navigate(`${calc.url}`)}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {calc.category}{" "}
        </Typography>
        <Typography variant="h6" component="div" className="card-title">
          {calc.name}
        </Typography>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          {calc.image ? (
            <img
              src={calc.image}
              alt={calc.name}
              style={{
                width: "30%",
                borderRadius: "50%",
              }}
            />
          ) : null}
          <Typography
            sx={{ mb: 0.2 }}
            color="text.secondary"
            className="card-desc"
          >
            {calc.description}
          </Typography>
        </Box>
      </CardContent>

      <CardActions
        style={{ alignSelf: "flex-end", justifyContent: "space-between" }}
        onClick={() => navigate(`${calc.url}`)}
      >
        <Button size="small" style={{ alignSelf: "flex-end" }}>
          Check it out
          <OutboundIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default DisplayCard;

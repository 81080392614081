import React, { useState } from "react";
import Logo from "../assets/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  // const handleSearch = () => {
  //   navigate("/search", {
  //     state: { searchTerm: searchTerm },
  //   });
  //   setSearchTerm("");
  // };

  // const handleEnter = (e) => {
  //   if (e.key === "Enter") {
  //     handleSearch();
  //   }
  // };

  return (
    <div className="header">
      <Link to="/">
        <img src={Logo} alt="calculatorshq" className="logo" />
      </Link>
      <div className="header-links">
        <Link to="/">Home</Link>

        <Link to="/calculators">Calculators</Link>
        <Link to="/constants">Constants</Link>
        <Link to="/equations">Equations</Link>
        <Link to="/conversions">Conversions</Link>
      </div>

      {/* <TextField
        id="search"
        type="search"
        className="search-bar"
        label="Find a calculator..."
        value={searchTerm}
        variant="standard"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => handleEnter(e)}
        sx={{ width: "25ch" }}
        style={{ padding: 0 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon onClick={handleSearch} />
            </InputAdornment>
          ),
        }}
      /> */}
    </div>
  );
};

export default Header;

import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const FrictionLoss = () => {
  const [diam, setDiam] = useState(2);
  const [diamUnit, setDiamUnit] = useState("meters (m)");

  const [length, setLength] = useState(20);
  const [lengthUnit, setLengthUnit] = useState("meters (m)");

  const [flowRate, setFlowRate] = useState(2);
  const [flowUnit, setFlowUnit] = useState("cubic meters per second (m^3/s)");

  const [material, setMaterial] = useState("Copper");
  const [frictionLoss, setFrictionLoss] = useState("");

  const distanceUnits = [
    "meters (m)",
    "feet (ft)",
    "inches (in)",
    "centimeters (cm)",
  ];

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  const flowRateUnits = [
    "US gallons per second (gal/s)",
    "US gallons per minute (gal/min)",
    "cubic feet per second (ft^3/s)",
    "cubic feet per minute (ft^3/min)",
    "cubic meters per second (m^3/s)",
    "cubic meters per minute (m^3/min)",
  ];

  const convertFlowRate = (flow, unit) => {
    if (unit === "US gallons per second (gal/s)") {
      return flow / 264.2;
    } else if (unit === "US gallons per minute (gal/min)") {
      return flow / 15850;
    } else if (unit === "cubic feet per second (ft^3/s)") {
      return flow / 35.315;
    } else if (unit === "cubic feet per minute (ft^3/min)") {
      return flow / 2119;
    } else if (unit === "cubic meters per minute (m^3/min)") {
      return flow / 60;
    }
    return flow;
  };

  const pipeRoughness = useMemo(() => {
    return {
      "Cast iron": 130,
      "Asbestos cement": 140,
      "Corrugated metal": 60,
      Concrete: 120,
      PVC: 150,
      Polyethylene: 140,
      Copper: 135,
      Brass: 135,
      Fiberglass: 150,
      Lead: 135,
      "Steel, seamless": 100,
      "Steel, unlined": 145,
      Nickel: 0.00005,
      Aluminum: 140,
      Tin: 130,
      Plastic: 140,
    };
  }, []);

  useEffect(() => {
    const convertedLength = convertDistance(length, lengthUnit);
    const convertedDiam = convertDistance(diam, diamUnit);
    const convertedFlowRate = convertFlowRate(flowRate, flowUnit);
    const headLoss =
      (10.67 * convertedLength * convertedFlowRate ** 1.852) /
      (pipeRoughness[material] ** 1.852 * convertedDiam ** 4.87);
    setFrictionLoss(headLoss.toFixed(3));
  }, [
    length,
    lengthUnit,
    diam,
    diamUnit,
    flowRate,
    flowUnit,
    pipeRoughness,
    material,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Friction Loss</Typography>
        <Typography variant="h6">
          Calculate how friction head loss along a pipe varies under different
          conditions.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="friction-input">
            <TextField
              label="Pipe Diameter"
              className="text-field"
              id="diam"
              variant="standard"
              type="number"
              defaultValue={diam}
              onChange={(e) => setDiam(e.target.value)}
            />
            <Select
              className="select-field"
              id="diam-unit"
              value={diamUnit}
              variant="standard"
              onChange={(e) => {
                setDiamUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="friction-input">
            <TextField
              label="Pipe Length"
              className="text-field"
              id="length"
              variant="standard"
              type="number"
              defaultValue={length}
              onChange={(e) => setLength(e.target.value)}
            />
            <Select
              className="select-field"
              id="length-unit"
              value={lengthUnit}
              variant="standard"
              onChange={(e) => {
                setLengthUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="friction-input">
            <TextField
              label="Volumetric Flow Rate"
              className="text-field"
              id="flowrate"
              variant="standard"
              type="number"
              defaultValue={flowRate}
              onChange={(e) => setFlowRate(e.target.value)}
            />
            <Select
              className="select-field"
              id="flowrate-unit"
              value={flowUnit}
              variant="standard"
              onChange={(e) => {
                setFlowUnit(e.target.value);
              }}
            >
              {flowRateUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="friction-input">
            <FormControl>
              <FormLabel id="condition">Pipe Material</FormLabel>
              <Select
                className="select-field"
                id="material-unit"
                value={material}
                variant="standard"
                onChange={(e) => {
                  setMaterial(e.target.value);
                }}
              >
                {Object.keys(pipeRoughness).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Typography variant="body">
          Pipe Roughness Factor for {material}:{" "}
          <span className="electro">
            {material ? pipeRoughness[material] : null}
          </span>
        </Typography>
        <Typography variant="body">
          Friction Head Loss (m of water):{" "}
          <span className="electro">{frictionLoss ? frictionLoss : null}</span>
        </Typography>
        <Typography variant="body">
          Friction Head Loss (ft of water):{" "}
          <span className="electro">
            {frictionLoss ? (frictionLoss * 3.281).toFixed(3) : null}
          </span>
        </Typography>

        <Typography variant="body">
          Friction Head Loss (kPa):{" "}
          <span className="electro">
            {frictionLoss ? (frictionLoss * 9.80665).toFixed(3) : null}
          </span>
        </Typography>
        <Typography variant="body">
          Pressure Loss (psi):{" "}
          <span className="electro">
            {frictionLoss ? (frictionLoss * 1.42233).toFixed(3) : null}
          </span>
        </Typography>
      </Box>
      <Typography variant="h3">Friction Loss Explained</Typography>

      <Typography paragraph>
        Calculating friction loss in a pipe is a critical aspect of fluid
        mechanics, particularly in applications such as plumbing, HVAC (heating,
        ventilation, and air conditioning) systems, and the design of pipelines.
        Friction loss refers to the loss of pressure in a fluid as it flows
        through a pipe due to the resistance encountered against the pipe walls.
        Several factors influence friction loss, including pipe roughness,
        diameter, length, and volumetric flow rate.
      </Typography>
      <Typography paragraph>
        The Darcy-Weisbach equation is commonly used to calculate friction loss
        in a pipe:
      </Typography>

      <MathJax>
        {
          "${ \\Delta P \\over L} = {{f} \\cdot  {\\rho \\over 2} \\cdot {v^2 \\over D}}$"
        }
      </MathJax>

      <Typography paragraph>where</Typography>

      <Typography paragraph>
        &Delta; P is the pressure drop (friction loss){" "}
      </Typography>
      <Typography paragraph>
        f is the Darcy friction factor (dependent on pipe roughness and Reynolds
        number){" "}
      </Typography>
      <Typography paragraph>L is the pipe length </Typography>
      <Typography paragraph>D is the pipe diameter</Typography>
      <Typography paragraph>&rho; is the fluid density</Typography>
      <Typography paragraph>v is the fluid velocity </Typography>

      <Typography paragraph>
        The Darcy friction factor f is a critical parameter and depends on the
        pipe roughness and the Reynolds number Re, which is a dimensionless
        quantity representing the ratio of inertial forces to viscous forces in
        the fluid. The Colebrook equation or Moody chart is often used to
        determine f based on the Reynolds number and pipe roughness.
      </Typography>

      <Typography paragraph>
        The relationship between friction loss and pipe diameter is inversely
        proportional, meaning larger diameter pipes generally experience lower
        friction losses for the same flow rate. Longer pipes, on the other hand,
        result in increased friction losses due to the extended surface area
        over which the fluid interacts with the pipe walls.
      </Typography>
      <Typography paragraph>
        The volumetric flow rate Q also plays a role, as higher flow rates
        increase the velocity of the fluid, leading to higher kinetic energy and
        subsequently more significant friction losses.
      </Typography>
      <Typography paragraph>
        Engineers use these calculations to design and optimize piping systems,
        ensuring that the desired flow rates are maintained while minimizing
        energy losses due to friction. Proper selection of pipe diameter,
        consideration of pipe roughness, and optimization of pipe length
        contribute to the efficient and effective operation of fluid transport
        systems.
      </Typography>
    </div>
  );
};

export default FrictionLoss;

const electronData = {
  H: {
    atomicMass: 1.008,
    atomicNumber: 1,
    electronConfig: "1s1",
    valenceElectrons: 1,
  },
  He: {
    atomicMass: 4.003,
    atomicNumber: 2,
    electronConfig: "1s2",
    valenceElectrons: 0,
  },
  Li: {
    atomicMass: 6.941,
    atomicNumber: 3,
    electronConfig: "[He] 2s1",
    valenceElectrons: 1,
  },
  Be: {
    atomicMass: 9.012,
    atomicNumber: 4,
    electronConfig: "[He] 2s2",
    valenceElectrons: 2,
  },
  B: {
    atomicMass: 10.81,
    atomicNumber: 5,
    electronConfig: "[He] 2s2 2p1",
    valenceElectrons: 3,
  },
  C: {
    atomicMass: 12.01,
    atomicNumber: 6,
    electronConfig: "[He] 2s2 2p2",
    valenceElectrons: 4,
  },
  N: {
    atomicMass: 14.01,
    atomicNumber: 7,
    electronConfig: "[He] 2s2 2p3",
    valenceElectrons: 5,
  },
  O: {
    atomicMass: 16.0,
    atomicNumber: 8,
    electronConfig: "[He] 2s2 2p4",
    valenceElectrons: 6,
  },
  F: {
    atomicMass: 19.0,
    atomicNumber: 9,
    electronConfig: "[He] 2s2 2p5",
    valenceElectrons: 7,
  },
  Ne: {
    atomicMass: 20.18,
    atomicNumber: 10,
    electronConfig: "[He] 2s2 2p6",
    valenceElectrons: 0,
  },
  Na: {
    atomicMass: 22.99,
    atomicNumber: 11,
    electronConfig: "[Ne] 3s1",
    valenceElectrons: 1,
  },
  Mg: {
    atomicMass: 24.31,
    atomicNumber: 12,
    electronConfig: "[Ne] 3s2",
    valenceElectrons: 2,
  },
  Al: {
    atomicMass: 26.98,
    atomicNumber: 13,
    electronConfig: "[Ne] 3s2 3p1",
    valenceElectrons: 3,
  },
  Si: {
    atomicMass: 28.09,
    atomicNumber: 14,
    electronConfig: "[Ne] 3s2 3p2",
    valenceElectrons: 4,
  },
  P: {
    atomicMass: 30.97,
    atomicNumber: 15,
    electronConfig: "[Ne] 3s2 3p3",
    valenceElectrons: 5,
  },
  S: {
    atomicMass: 32.06,
    atomicNumber: 16,
    electronConfig: "[Ne] 3s2 3p4",
    valenceElectrons: 6,
  },
  Cl: {
    atomicMass: 35.45,
    atomicNumber: 17,
    electronConfig: "[Ne] 3s2 3p5",
    valenceElectrons: 7,
  },
  Ar: {
    atomicMass: 39.95,
    atomicNumber: 18,
    electronConfig: "[Ne] 3s2 3p6",
    valenceElectrons: 0,
  },
  K: {
    atomicMass: 39.1,
    atomicNumber: 19,
    electronConfig: "[Ar] 4s1",
    valenceElectrons: 1,
  },
  Ca: {
    atomicMass: 40.08,
    atomicNumber: 20,
    electronConfig: "[Ar] 4s2",
    valenceElectrons: 2,
  },
  Sc: {
    atomicMass: 44.96,
    atomicNumber: 21,
    electronConfig: "[Ar] 3d1 4s2",
    valenceElectrons: 3,
  },
  Ti: {
    atomicMass: 47.87,
    atomicNumber: 22,
    electronConfig: "[Ar] 3d2 4s2",
    valenceElectrons: 4,
  },
  V: {
    atomicMass: 50.94,
    atomicNumber: 23,
    electronConfig: "[Ar] 3d3 4s2",
    valenceElectrons: 5,
  },
  Cr: {
    atomicMass: 52.0,
    atomicNumber: 24,
    electronConfig: "[Ar] 3d5 4s1",
    valenceElectrons: 6,
  },
  Mn: {
    atomicMass: 54.94,
    atomicNumber: 25,
    electronConfig: "[Ar] 3d5 4s2",
    valenceElectrons: 7,
  },
  Fe: {
    atomicMass: 55.85,
    atomicNumber: 26,
    electronConfig: "[Ar] 3d6 4s2",
    valenceElectrons: 8,
  },
  Co: {
    atomicMass: 58.93,
    atomicNumber: 27,
    electronConfig: "[Ar] 3d7 4s2",
    valenceElectrons: 9,
  },
  Ni: {
    atomicMass: 58.69,
    atomicNumber: 28,
    electronConfig: "[Ar] 3d8 4s2",
    valenceElectrons: 10,
  },
  Cu: {
    atomicMass: 63.55,
    atomicNumber: 29,
    electronConfig: "[Ar] 3d10 4s1",
    valenceElectrons: 11,
  },
  Zn: {
    atomicMass: 65.38,
    atomicNumber: 30,
    electronConfig: "[Ar] 3d10 4s2",
    valenceElectrons: 12,
  },
  Ga: {
    atomicMass: 69.72,
    atomicNumber: 31,
    electronConfig: "[Ar] 3d10 4s2 4p1",
    valenceElectrons: 3,
  },
  Ge: {
    atomicMass: 72.63,
    atomicNumber: 32,
    electronConfig: "[Ar] 3d10 4s2 4p2",
    valenceElectrons: 4,
  },
  As: {
    atomicMass: 74.92,
    atomicNumber: 33,
    electronConfig: "[Ar] 3d10 4s2 4p3",
    valenceElectrons: 5,
  },
  Se: {
    atomicMass: 78.96,
    atomicNumber: 34,
    electronConfig: "[Ar] 3d10 4s2 4p4",
    valenceElectrons: 6,
  },
  Br: {
    atomicMass: 79.9,
    atomicNumber: 35,
    electronConfig: "[Ar] 3d10 4s2 4p5",
    valenceElectrons: 7,
  },
  Kr: {
    atomicMass: 83.8,
    atomicNumber: 36,
    electronConfig: "[Ar] 3d10 4s2 4p6",
    valenceElectrons: 0,
  },
  Rb: {
    atomicMass: 85.47,
    atomicNumber: 37,
    electronConfig: "[Kr] 5s1",
    valenceElectrons: 1,
  },
  Sr: {
    atomicMass: 87.62,
    atomicNumber: 38,
    electronConfig: "[Kr] 5s2",
    valenceElectrons: 2,
  },
  Y: {
    atomicMass: 88.91,
    atomicNumber: 39,
    electronConfig: "[Kr] 4d1 5s2",
    valenceElectrons: 3,
  },
  Zr: {
    atomicMass: 91.22,
    atomicNumber: 40,
    electronConfig: "[Kr] 4d2 5s2",
    valenceElectrons: 4,
  },
  Nb: {
    atomicMass: 92.91,
    atomicNumber: 41,
    electronConfig: "[Kr] 4d4 5s1",
    valenceElectrons: 5,
  },
  Mo: {
    atomicMass: 95.94,
    atomicNumber: 42,
    electronConfig: "[Kr] 4d5 5s1",
    valenceElectrons: 6,
  },
  Tc: {
    atomicMass: 98,
    atomicNumber: 43,
    electronConfig: "[Kr] 4d5 5s2",
    valenceElectrons: 7,
  },
  Ru: {
    atomicMass: 101.07,
    atomicNumber: 44,
    electronConfig: "[Kr] 4d7 5s1",
    valenceElectrons: 8,
  },
  Rh: {
    atomicMass: 102.91,
    atomicNumber: 45,
    electronConfig: "[Kr] 4d8 5s1",
    valenceElectrons: 9,
  },
  Pd: {
    atomicMass: 106.42,
    atomicNumber: 46,
    electronConfig: "[Kr] 4d10",
    valenceElectrons: 10,
  },
  Ag: {
    atomicMass: 107.87,
    atomicNumber: 47,
    electronConfig: "[Kr] 4d10 5s1",
    valenceElectrons: 1,
  },
  Cd: {
    atomicMass: 112.41,
    atomicNumber: 48,
    electronConfig: "[Kr] 4d10 5s2",
    valenceElectrons: 2,
  },
  In: {
    atomicMass: 114.82,
    atomicNumber: 49,
    electronConfig: "[Kr] 4d10 5s2 5p1",
    valenceElectrons: 3,
  },
  Sn: {
    atomicMass: 118.71,
    atomicNumber: 50,
    electronConfig: "[Kr] 4d10 5s2 5p2",
    valenceElectrons: 4,
  },
  Sb: {
    atomicMass: 121.76,
    atomicNumber: 51,
    electronConfig: "[Kr] 4d10 5s2 5p3",
    valenceElectrons: 5,
  },
  Te: {
    atomicMass: 127.6,
    atomicNumber: 52,
    electronConfig: "[Kr] 4d10 5s2 5p4",
    valenceElectrons: 6,
  },
  I: {
    atomicMass: 126.9,
    atomicNumber: 53,
    electronConfig: "[Kr] 4d10 5s2 5p5",
    valenceElectrons: 7,
  },
  Xe: {
    atomicMass: 131.29,
    atomicNumber: 54,
    electronConfig: "[Kr] 4d10 5s2 5p6",
    valenceElectrons: 0,
  },
  Cs: {
    atomicMass: 132.91,
    atomicNumber: 55,
    electronConfig: "[Xe] 6s1",
    valenceElectrons: 1,
  },
  Ba: {
    atomicMass: 137.33,
    atomicNumber: 56,
    electronConfig: "[Xe] 6s2",
    valenceElectrons: 2,
  },
  La: {
    atomicMass: 138.91,
    atomicNumber: 57,
    electronConfig: "[Xe] 5d1 6s2",
    valenceElectrons: 3,
  },
  Ce: {
    atomicMass: 140.12,
    atomicNumber: 58,
    electronConfig: "[Xe] 4f1 5d1 6s2",
    valenceElectrons: 4,
  },
  Pr: {
    atomicMass: 140.91,
    atomicNumber: 59,
    electronConfig: "[Xe] 4f3 6s2",
    valenceElectrons: 4,
  },
  Nd: {
    atomicMass: 144.24,
    atomicNumber: 60,
    electronConfig: "[Xe] 4f4 6s2",
    valenceElectrons: 3,
  },

  Pm: {
    atomicMass: 145,
    atomicNumber: 61,
    electronConfig: "[Xe] 4f5 6s2",
    valenceElectrons: 3,
  },
  Sm: {
    atomicMass: 150.36,
    atomicNumber: 62,
    electronConfig: "[Xe] 4f6 6s2",
    valenceElectrons: 2,
  },
  Eu: {
    atomicMass: 151.96,
    atomicNumber: 63,
    electronConfig: "[Xe] 4f7 6s2",
    valenceElectrons: 2,
  },
  Gd: {
    atomicMass: 157.25,
    atomicNumber: 64,
    electronConfig: "[Xe] 4f7 5d1 6s2",
    valenceElectrons: 3,
  },
  Tb: {
    atomicMass: 158.93,
    atomicNumber: 65,
    electronConfig: "[Xe] 4f9 6s2",
    valenceElectrons: 4,
  },
  Dy: {
    atomicMass: 162.5,
    atomicNumber: 66,
    electronConfig: "[Xe] 4f10 6s2",
    valenceElectrons: 5,
  },
  Ho: {
    atomicMass: 164.93,
    atomicNumber: 67,
    electronConfig: "[Xe] 4f11 6s2",
    valenceElectrons: 6,
  },
  Er: {
    atomicMass: 167.26,
    atomicNumber: 68,
    electronConfig: "[Xe] 4f12 6s2",
    valenceElectrons: 6,
  },
  Tm: {
    atomicMass: 168.93,
    atomicNumber: 69,
    electronConfig: "[Xe] 4f13 6s2",
    valenceElectrons: 6,
  },
  Yb: {
    atomicMass: 173.05,
    atomicNumber: 70,
    electronConfig: "[Xe] 4f14 6s2",
    valenceElectrons: 6,
  },
  Lu: {
    atomicMass: 175,
    atomicNumber: 71,
    electronConfig: "[Xe] 4f14 5d1 6s2",
    valenceElectrons: 3,
  },
  Hf: {
    atomicMass: 178.49,
    atomicNumber: 72,
    electronConfig: "[Xe] 4f14 5d2 6s2",
    valenceElectrons: 4,
  },
  Ta: {
    atomicMass: 180.95,
    atomicNumber: 73,
    electronConfig: "[Xe] 4f14 5d3 6s2",
    valenceElectrons: 5,
  },
  W: {
    atomicMass: 183.84,
    atomicNumber: 74,
    electronConfig: "[Xe] 4f14 5d4 6s2",
    valenceElectrons: 6,
  },
};

export default electronData;

import React from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Search = () => {
  const { state } = useLocation();
  let { searchTerm } = state;
  searchTerm = searchTerm.toLowerCase();
  console.log(searchTerm);
  const filteredCalculators = CalculatorData.filter(
    (calc) =>
      calc.name.toLowerCase().includes(searchTerm) ||
      calc.category.toLowerCase().includes(searchTerm) ||
      calc.description.toLowerCase().includes(searchTerm) ||
      calc.paragraph.toLowerCase().includes(searchTerm)
  );

  return (
    <div>
      <Typography variant="h3">Search Results for: {searchTerm} </Typography>
      <Box className="display-cards">
        {filteredCalculators.map((calc) => {
          return <DisplayCard calc={calc} />;
        })}
      </Box>
      {filteredCalculators.length === 0 ? (
        <Typography variant="body">
          No calculators found. Find all our calculators{" "}
          <Link to="/">here.</Link>
        </Typography>
      ) : null}
    </div>
  );
};

export default Search;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { MathJax } from "better-react-mathjax";

const WindLoad = () => {
  const [windVel, setWindVel] = useState(50);
  const [windVelUnit, setWindVelUnit] = useState("miles per hour (mph)");
  const [surfArea, setSurfArea] = useState(10);
  const [surfAreaUnit, setSurfAreaUnit] = useState("square feet");
  const [angle, setAngle] = useState(90);
  const [angleUnit, setAngleUnit] = useState("degrees");

  const [dynPressure, setDynPressure] = useState(0);
  const [windLoad, setWindLoad] = useState(0);

  const speedUnits = [
    "meters per second (m/s)",
    "kilometers per hour (km/h)",
    "feet per second (ft/s)",
    "miles per hour (mph)",
  ];

  const angleUnits = ["degrees", "radians"];

  const convertSpeed = (speed, unit) => {
    if (unit === "kilometers per hour (km/h)") {
      return speed / 3.6;
    } else if (unit === "feet per second (ft/s)") {
      return speed / 3.281;
    } else if (unit === "miles per hour (mph)") {
      return speed / 2.237;
    }
    return speed;
  };

  const convertAngle = (angle, unit) => {
    if (unit === "degrees") {
      return (+angle * 3.14) / 180;
    }
    return angle;
  };

  const convertArea = (area, unit) => {
    if (unit === "square feet") {
      return area / 10.764;
    } else if (unit === "square inches") {
      return area / 1550;
    }
    return area;
  };

  useEffect(() => {
    const convertedVelocity = convertSpeed(windVel, windVelUnit);
    const pressure = 0.5 * 1.225 * convertedVelocity ** 2;
    setDynPressure(pressure.toFixed(3));
    const convertedArea = convertArea(surfArea, surfAreaUnit);
    const convertedAngle = convertAngle(angle, angleUnit);
    const load = pressure * convertedArea * Math.sin(convertedAngle);
    setWindLoad(load.toFixed(3));
  }, [windVel, windVelUnit, surfArea, surfAreaUnit, angle, angleUnit]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Wind Load</Typography>
        <Typography variant="h6">
          Calculate the wind load on a structure. This calcultor assumes
          standard temperature & pressure (STP) conditions.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="wind-input">
            <TextField
              label="Wind Velocity"
              className="text-field"
              id="vel"
              variant="standard"
              type="number"
              defaultValue={windVel}
              onChange={(e) => setWindVel(e.target.value)}
            />
            <Select
              className="select-field"
              id="vel-unit"
              value={windVelUnit}
              variant="standard"
              onChange={(e) => {
                setWindVelUnit(e.target.value);
              }}
            >
              {speedUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="wind-input">
            <TextField
              label="Surface Area of Object"
              className="text-field"
              id="surf-area"
              variant="standard"
              type="number"
              defaultValue={surfArea}
              onChange={(e) => setSurfArea(e.target.value)}
            />
            <Select
              className="select-field"
              id="area-unit"
              value={surfAreaUnit}
              variant="standard"
              onChange={(e) => {
                setSurfAreaUnit(e.target.value);
              }}
            >
              <MenuItem value="square feet">
                <span>
                  square feet (ft<sup>2</sup>)
                </span>
              </MenuItem>
              <MenuItem value="square meters">
                <span>
                  square meters (m<sup>2</sup>)
                </span>
              </MenuItem>
              <MenuItem value="square inches">
                <span>
                  square inches (in<sup>2</sup>)
                </span>
              </MenuItem>
            </Select>
          </Box>

          <Box className="wind-input">
            <TextField
              label="Angle of Wind on Surface"
              className="text-field"
              id="wind-angle"
              variant="standard"
              type="number"
              defaultValue={angle}
              onChange={(e) => setAngle(e.target.value)}
            />
            <Select
              className="select-field"
              id="angle-unit"
              value={angleUnit}
              variant="standard"
              onChange={(e) => {
                setAngleUnit(e.target.value);
              }}
            >
              {angleUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>

        <Typography variant="body">
          Dynamic Pressure (Pa):{" "}
          <span className="electro"> {dynPressure ? dynPressure : null}</span>
        </Typography>

        <Typography variant="body">
          Wind Load (N):
          <span className="electro"> {windLoad ? windLoad : null}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Wind Load Explained</Typography>
      <Typography paragraph>
        Calculating windLoad load is a critical aspect of structural
        engineering, especially when designing buildings, bridges, or other
        structures exposed to windLoad forces. Wind load is the force exerted by
        the windLoad on a structure, and it is influenced by factors such as
        windLoad speed, angle of incidence, and the surface area of the object
        facing the windLoad. Understanding and accurately calculating windLoad
        loads are essential for ensuring the safety and stability of structures
        under various windLoad conditions.
      </Typography>
      <Typography>
        The basic formula for calculating windLoad load F is given by
      </Typography>

      <MathJax>
        {
          "${F} = {{1\\over 2} \\times C_d \\times A \\times \\rho \\times V^2}$"
        }
      </MathJax>

      <Typography>where</Typography>
      <Typography>F is the windLoad force</Typography>

      <Typography>
        C<sub>d</sub> is the drag coefficient
      </Typography>
      <Typography>A is the reference area facing the wind load</Typography>
      <Typography>&rho; is the air density</Typography>
      <Typography>V is the windLoad speed.</Typography>

      <Typography paragraph>
        The drag coefficient is a dimensionless factor that depends on the shape
        of the object and the angle of incidence of the wind load. It represents
        how aerodynamic or streamlined an object is. For common shapes, such as
        flat plates or cylinders, empirical values for the coefficient can be
        found in engineering literature.
      </Typography>
      <Typography paragraph>
        The reference area A is the projected area of the object facing the
        windLoad. For example, in the case of a rectangular building, A would be
        the product of the width and height of the side facing the windLoad.
      </Typography>
      <Typography paragraph>
        The air density &rho; depends on environmental conditions such as
        temperature and altitude. It is typically assumed to be constant at sea
        level for standard atmospheric conditions.
      </Typography>
      <Typography paragraph>
        The windLoad speed V is a crucial factor, and its influence on windLoad
        load is quadratic. This means that even a small increase in windLoad
        speed can lead to a significant increase in windLoad load.
      </Typography>
      <Typography>
        To account for the angle of incidence (&theta;) of the windLoad, the
        formula can be modified as follows:
      </Typography>
      <MathJax>
        {
          "${F} = {{1\\over 2} \\times C_d \\times A \\times \\rho \\times V^2 \\times cos(\\theta)}$"
        }
      </MathJax>

      <Typography paragraph>
        This modification considers that the effective area facing the windLoad
        is reduced when the windLoad is incident at an angle.
      </Typography>
      <Typography paragraph>
        Engineers use these formulas to assess and design structures that can
        withstand the windLoad loads they are likely to encounter in their
        specific geographical locations. Wind load calculations play a vital
        role in ensuring the structural integrity and safety of buildings and
        other structures, particularly in areas prone to strong winds or
        hurricanes.
      </Typography>
    </div>
  );
};

export default WindLoad;

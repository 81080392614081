import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import elementMass from "../data/ElectronMassData";
import TextField from "@mui/material/TextField";
import PieChart, { Series, Label, Legend } from "devextreme-react/pie-chart";
import { MathJax } from "better-react-mathjax";

const PercentComposition = () => {
  const [elementOne, setElementOne] = useState("");
  const [quantityElementOne, setQuantityElementOne] = useState("");
  const [elementTwo, setElementTwo] = useState("");
  const [quantityElementTwo, setQuantityElementTwo] = useState("");
  const [elementThree, setElementThree] = useState("");
  const [quantityElementThree, setQuantityElementThree] = useState("");
  const [elementFour, setElementFour] = useState("");
  const [quantityElementFour, setQuantityElementFour] = useState("");

  const findMolecularWeight = () => {
    const weight =
      elementOne * quantityElementOne +
      elementTwo * quantityElementTwo +
      elementThree * quantityElementThree +
      elementFour * quantityElementFour;
    return weight.toFixed(3);
  };

  const findKey = (value) => {
    const key = Object.keys(elementMass).find(
      (key) => elementMass[key] === value
    );
    return key;
  };

  const findPercent = (x, y) => {
    const percent = ((x * y) / findMolecularWeight()) * 100;
    return percent.toFixed(3);
  };

  const dataSource =
    elementOne || elementTwo || elementThree || elementFour
      ? [
          {
            argument: findKey(elementOne),
            value: findPercent(elementOne, quantityElementOne),
          },
          {
            argument: findKey(elementTwo),
            value: findPercent(elementTwo, quantityElementTwo),
          },
          {
            argument: findKey(elementThree),
            value: findPercent(elementThree, quantityElementThree),
          },
          {
            argument: findKey(elementFour),
            value: findPercent(elementFour, quantityElementFour),
          },
        ]
      : null;

  const pie = (
    <PieChart
      className="pie"
      key="element-pie"
      title="Percent Composition by Mass"
      palette="Soft Pastel"
      sizeGroup="piesGroup"
      dataSource={dataSource}
    >
      <Series argumentField="argument" valueField="value">
        <Label visible={true} />
      </Series>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="right"
        rowCount={2}
      />
    </PieChart>
  );

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Percent Composition </Typography>
        <Typography variant="h6">
          What is the percent composition by mass of a compound based on its
          molecular formula?
        </Typography>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-one">Element 1</InputLabel>
          <Select
            className="select-field"
            labelId="element-one"
            variant="standard"
            id="element-one"
            value={elementOne}
            label="Element"
            onChange={(e) => setElementOne(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementOne(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-two">Element 2</InputLabel>
          <Select
            className="select-field"
            labelId="element-two"
            variant="standard"
            id="element-two"
            defaultValue={elementTwo}
            label="Element"
            onChange={(e) => setElementTwo(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementTwo(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-three">Element 3</InputLabel>
          <Select
            className="select-field"
            labelId="element-three"
            variant="standard"
            id="element-three"
            defaultValue={elementThree}
            label="Element"
            onChange={(e) => setElementThree(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementThree(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <InputLabel id="element-four">Element 4</InputLabel>
          <Select
            className="select-field"
            labelId="element-four"
            variant="standard"
            id="element-four"
            defaultValue={elementFour}
            label="Element"
            onChange={(e) => setElementFour(e.target.value)}
          >
            {Object.entries(elementMass).map(([key, value]) => {
              return <MenuItem value={value}>{key}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Quantity"
            className="text-field"
            variant="standard"
            onChange={(e) => setQuantityElementFour(e.target.value)}
          />
        </FormControl>
        <Typography variant="body1" gutterBottom>
          Molecular Weight:{" "}
          <span className="electro">{findMolecularWeight()} amu</span>
        </Typography>
        <Box className="percent-comp-output">
          <Typography variant="body">Element</Typography>
          <Typography variant="body">Element Mass in Compound (amu)</Typography>
          <Typography variant="body">Percent Composition (%)</Typography>
          <Typography variant="body"> {findKey(elementOne)}</Typography>
          <Typography variant="body">
            {elementOne ? elementOne * quantityElementOne : null}
          </Typography>
          <Typography variant="body">
            {elementOne ? findPercent(elementOne, quantityElementOne) : null}
          </Typography>
          <Typography variant="body"> {findKey(elementTwo)}</Typography>
          <Typography variant="body">
            {elementTwo ? elementTwo * quantityElementTwo : null}
          </Typography>
          <Typography variant="body">
            {elementTwo ? findPercent(elementTwo, quantityElementTwo) : null}
          </Typography>
          <Typography variant="body"> {findKey(elementThree)}</Typography>
          <Typography variant="body">
            {elementThree ? elementThree * quantityElementThree : null}
          </Typography>
          <Typography variant="body">
            {elementThree
              ? findPercent(elementThree, quantityElementThree)
              : null}
          </Typography>
          <Typography variant="body"> {findKey(elementFour)}</Typography>
          <Typography variant="body">
            {elementFour ? elementFour * quantityElementFour : null}
          </Typography>
          <Typography variant="body">
            {elementFour ? findPercent(elementFour, quantityElementFour) : null}
          </Typography>
        </Box>
      </Box>

      {elementOne || elementTwo || elementThree || elementFour ? pie : null}

      <Typography variant="h3">Percent Composition Explained</Typography>
      <Typography paragraph>
        The percent composition by mass of a molecule is a fundamental concept
        in chemistry that provides insights into the relative abundance of
        elements within a compound. It is defined as the percentage of the total
        mass of a compound that comes from each individual element present in
        that compound. The percent composition is a critical factor in
        understanding and characterizing chemical compounds, as it helps
        elucidate their properties and behaviors.
      </Typography>
      <Typography paragraph>
        To calculate the percent composition by mass of a molecule, one must
        first determine the molar mass of the compound. The molar mass is the
        mass of one mole of the substance and is obtained by summing the atomic
        masses of all the atoms present in the molecular formula. The percent
        composition of each element is then calculated by dividing the mass
        contributed by that element by the total molar mass of the compound and
        multiplying by 100 to express the result as a percentage.
      </Typography>
      <Typography paragraph>
        For example, consider water (H₂O). The molar mass of water is obtained
        by adding the molar masses of two hydrogen atoms (each with a molar mass
        of approximately 1 g/mol) and one oxygen atom (with a molar mass of
        approximately 16 g/mol). The total molar mass of water is then 18 g/mol.
        The percent composition of hydrogen in water is calculated as (2 g / 18
        g) * 100 ≈ 11.1%, and the percent composition of oxygen is (16 g / 18 g)
        * 100 ≈ 88.9%.
      </Typography>
      <Typography paragraph>
        Understanding percent composition is valuable in various aspects of
        chemistry. It aids in determining the stoichiometry of chemical
        reactions, predicting the behavior of substances in reactions, and
        identifying unknown compounds based on experimental data. Percent
        composition is also crucial in analytical chemistry, where it is used to
        assess the purity of synthesized compounds or to quantify the
        composition of complex mixtures.
      </Typography>
      <Typography paragraph>
        In summary, percent composition by mass is a fundamental concept that
        provides a quantitative understanding of the elemental makeup of
        chemical compounds. It is a key tool for chemists in characterizing
        substances and predicting their behavior in diverse chemical processes.
      </Typography>
    </div>
  );
};

export default PercentComposition;

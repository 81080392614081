import React, { useEffect, useState, useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MathJax } from "better-react-mathjax";

const PlanetWeight = () => {
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("pounds (lbs)");
  const [calcDone, setCalcDone] = useState(false);
  const [weights, setWeights] = useState([]);
  const unitRef = useRef();

  const weightUnits = [
    "pounds (lbs)",
    "kilograms (kg)",
    "grams (g)",
    "ounces (oz)",
  ];

  const convertWeight = (weight, unit) => {
    if (unit === "kilograms (kg)") {
      return weight * 2.205;
    } else if (unit === "grams (g)") {
      return weight / 453.592;
    } else if (unit === "ounces (oz)") {
      return weight / 16;
    }
    return weight;
  };

  const surfaceGravity = useMemo(() => {
    return {
      Mercury: 3.7,
      Venus: 8.87,
      Earth: 9.81,
      Mars: 3.71,
      Jupiter: 24.79,
      Saturn: 10.44,
      Uranus: 8.87,
      Neptune: 11.15,
      Pluto: 0.62,
    };
  }, []);

  useEffect(() => {
    if (weight) {
      const newArr = Object.values(surfaceGravity).map((item) => {
        const newWeight = (item / 9.81) * weight;
        return newWeight.toFixed(3);
      });
      setWeights((prev) => newArr);
      setCalcDone(true);
    }
  }, [weight, surfaceGravity]);

  const clearFields = () => {
    document.getElementById("weight").value = "";
    setWeights((prev) => []);
    setWeightUnit("pounds (lbs)");
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Weight on Other Planets</Typography>
        <Typography variant="h6">
          How does your weight vary from one planet to another?
        </Typography>
        <Typography variant="body">
          Enter your current weight on Earth and find out your weight on other
          planets.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="planet-weight-input">
            <TextField
              label="Weight"
              className="text-field"
              id="weight"
              variant="standard"
              type="number"
              defaultValue={weight}
              onChange={(e) => {
                setWeight(e.target.value);
                setWeights((prev) => []);
              }}
            />
            <Select
              className="select-field"
              // defaultValue="pounds (lbs)"
              id="weight-unit"
              ref={unitRef}
              value={weightUnit}
              variant="standard"
              onChange={(e) => {
                setWeightUnit(e.target.value);
                setWeight(
                  convertWeight(
                    document.getElementById("weight").value,
                    e.target.value
                  )
                );
              }}
            >
              {weightUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        {calcDone ? (
          <div>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridAutoFlow: "column",
              }}
              className="planets"
            >
              <Typography
                variant="body1"
                sx={{ gridColumn: 1, fontWeight: "bold" }}
              >
                Planet{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{ gridColumn: 2, fontWeight: "bold" }}
              >
                {`Weight (lbs)`}
              </Typography>
              {Object.keys(surfaceGravity).map((item) => {
                return (
                  <Typography variant="body1" key={item} sx={{ gridColumn: 1 }}>
                    {item}
                  </Typography>
                );
              })}

              {weights.map((item, i) => {
                return (
                  <Typography variant="body1" key={i} sx={{ gridColumn: 2 }}>
                    {item}
                  </Typography>
                );
              })}
            </Box>

            <Button
              variant="outlined"
              sx={{ display: "block" }}
              onClick={clearFields}
            >
              Reset
            </Button>
          </div>
        ) : null}
      </Box>

      <Typography variant="h3">Planet Weight Explained</Typography>
      <Typography paragraph>
        Determining someone's weight on different planets involves understanding
        the concept of gravitational force and how it varies across celestial
        bodies. On Earth, weight is the measure of the gravitational force
        exerted by the planet on an object. This force is directly proportional
        to the object's mass and inversely proportional to the square of the
        distance between the object's center of mass and the center of the
        planet.
      </Typography>
      <Typography paragraph>
        To calculate someone's weight on a different planet, you can use the
        formula:
      </Typography>

      <MathJax>{"${W = m \\cdot g}$"}</MathJax>

      <Typography>where W is the weight of the object</Typography>
      <Typography>m is the mass of the object</Typography>
      <Typography>
        g is the acceleration due to gravity on the planet
      </Typography>
      <Typography paragraph>
        The acceleration due to gravity g varies from planet to planet. For
        instance, on Earth, g is approximately 9.81 m/s<sup>2</sup>. On other
        celestial bodies, such as Mars or the Moon, g is different due to
        variations in mass and radius.
      </Typography>
      <Typography paragraph>
        To determine someone's weight on a different planet, you would first
        calculate their mass, typically measured in kilograms. Then, you would
        use the known acceleration due to gravity of the target planet to
        compute their weight. For example, on Mars, where g is approximately
        3.71 m/s<sup>2</sup>, a person with a mass of 70 kg would weigh
      </Typography>

      <MathJax>{"${W_{Mars} = mg = 70 \\cdot 3.71 = 260N}$"}</MathJax>

      <Typography paragraph>
        This means that on Mars, the person would weigh approximately 260 N, or
        about 26.5% of their weight on Earth. Similarly, you can calculate
        weights on other celestial bodies by using their respective values for
        g.
      </Typography>
      <Typography paragraph>
        Understanding how weight varies across different planets not only
        provides insight into celestial mechanics but also has practical
        implications for space exploration, such as designing equipment and
        spacecraft that can function effectively under different gravitational
        conditions.
      </Typography>
    </div>
  );
};

export default PlanetWeight;

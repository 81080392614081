import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const PipeFlow = () => {
  const [diam, setDiam] = useState(50);
  const [diamUnit, setDiamUnit] = useState("meters (m)");

  const [material, setMaterial] = useState("Copper");

  const [length, setLength] = useState(10);
  const [lengthUnit, setLengthUnit] = useState("meters (m)");

  const [drop, setDrop] = useState(7);
  const [dropUnit, setDropUnit] = useState("meters (m)");

  const [velocity, setVelocity] = useState(0);
  const [volFlow, setVolFlow] = useState(0);

  const distanceUnits = [
    "meters (m)",
    "feet (ft)",
    "inches (in)",
    "centimeters (cm)",
  ];

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  const pipeRoughness = useMemo(() => {
    return {
      "Cast iron": 130,
      "Asbestos cement": 140,
      "Corrugated metal": 60,
      Concrete: 120,
      PVC: 150,
      Polyethylene: 140,
      Copper: 135,
      Brass: 135,
      Fiberglass: 150,
      Lead: 135,
      "Steel, seamless": 100,
      "Steel, unlined": 145,
      Aluminum: 140,
      Tin: 130,
      Plastic: 140,
    };
  }, []);

  useEffect(() => {
    const convertedDiam = parseFloat(convertDistance(diam, diamUnit));
    const convertedLength = parseFloat(convertDistance(length, lengthUnit));
    const convertedDrop = parseFloat(convertDistance(drop, dropUnit));

    console.log(convertedDiam, convertedLength, convertedDrop);

    const hydRad = convertedDiam / 4;
    const slope = convertedDrop / convertedLength;
    console.log(slope);
    console.log(pipeRoughness[material]);

    const vel =
      0.849 * pipeRoughness[material] * hydRad ** 0.63 * slope ** 0.54;
    console.log(vel);
    const flow = (vel * 3.14 * convertedDiam ** 2) / 4;
    console.log(flow, typeof flow);

    setVelocity(vel);
    setVolFlow(flow);
  }, [
    diam,
    diamUnit,
    length,
    lengthUnit,
    drop,
    dropUnit,
    pipeRoughness,
    material,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Pipe Flow</Typography>
        <Typography variant="h6">
          Calculate the flow velocity and volumetric flow rate in a pipe based
          on various conditions.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="flow-input">
            <TextField
              label="Pipe Diameter"
              className="text-field"
              id="diam"
              variant="standard"
              type="number"
              defaultValue={diam}
              onChange={(e) => setDiam(e.target.value)}
            />
            <Select
              className="select-field"
              id="diam-unit"
              value={diamUnit}
              variant="standard"
              onChange={(e) => {
                setDiamUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="flow-input">
            <TextField
              label="Pipe Length"
              className="text-field"
              id="length"
              variant="standard"
              type="number"
              defaultValue={length}
              onChange={(e) => setLength(e.target.value)}
            />
            <Select
              className="select-field"
              id="length-unit"
              value={lengthUnit}
              variant="standard"
              onChange={(e) => {
                setLengthUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="flow-input">
            <TextField
              label="Pipe Drop"
              className="text-field"
              id="drop"
              variant="standard"
              type="number"
              defaultValue={drop}
              onChange={(e) => setDrop(e.target.value)}
            />
            <Select
              className="select-field"
              id="drop-unit"
              value={dropUnit}
              variant="standard"
              onChange={(e) => {
                setDropUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="flow-input">
            <FormControl>
              <FormLabel id="condition">Pipe Material</FormLabel>
              <Select
                className="select-field"
                id="material"
                value={material}
                variant="standard"
                onChange={(e) => {
                  setMaterial(e.target.value);
                }}
              >
                {Object.keys(pipeRoughness).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Typography variant="body">
          Roughness Coefficient:{" "}
          <span className="electro">
            {material ? pipeRoughness[material] : null}
          </span>
        </Typography>
        <Typography variant="body">
          Velocity (m/s):{" "}
          <span className="electro">
            {velocity ? velocity.toFixed(3) : null}
          </span>
        </Typography>
        <Typography variant="body">
          Volumetric Flow Rate (m<sup>3</sup>/s):{" "}
          <span className="electro">{volFlow ? volFlow.toFixed(3) : null}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Pipe Flow Explained</Typography>
      <Typography paragraph>
        Calculating pipe flow velocity and volumetric flow rate is crucial in
        fluid mechanics and engineering, especially when designing piping
        systems or analyzing fluid transport. The flow velocity V and volumetric
        flow rate Q are interrelated parameters that provide insights into the
        dynamics of fluid movement within pipes.
      </Typography>
      <Typography paragraph>
        The volumetric flow rate represents the volume of fluid passing through
        a cross-section of a pipe per unit of time. It is calculated using the
        equation
      </Typography>

      <MathJax>{"${Q = A \\times V}$"}</MathJax>

      <Typography paragraph>
        where A is the cross-sectional area of the pipe and V is the flow
        velocity. The unit of volumetric flow rate is typically expressed as
        cubic meters per second (m³/s) in the metric system or cubic feet per
        second (ft³/s) in the imperial system.
      </Typography>
      <Typography paragraph>
        The flow velocity in a pipe can be determined using the volumetric flow
        rate and pipe dimensions. The relationship is expressed as
      </Typography>

      <MathJax>{"${V} = {Q \\over A}$"}</MathJax>
      <Typography paragraph>
        where Q is the volumetric flow rate and A is the cross-sectional area of
        the pipe. The flow velocity is a critical parameter in fluid dynamics,
        influencing factors such as pressure drop, energy consumption, and the
        efficiency of fluid transport.
      </Typography>
      <Typography paragraph>
        For a circular pipe, the cross-sectional area A is given by
      </Typography>

      <MathJax>{"${A} = {\\pi r^2}$"}</MathJax>
      <Typography paragraph>
        where r is the radius of the pipe. The volumetric flow rate Q is then
        calculated using the equation mentioned earlier. For pipes with
        non-circular cross-sections, the appropriate formula for the
        cross-sectional area must be used based on the specific geometry of the
        pipe.
      </Typography>
      <Typography paragraph>
        Understanding and calculating flow velocity and volumetric flow rate are
        essential for designing piping systems that meet specific requirements,
        ensuring that the fluid is transported efficiently and with minimal
        energy losses. Engineers use these calculations to optimize pipe
        dimensions, select appropriate pump sizes, and assess the overall
        performance of fluid transport systems in various engineering
        applications.
      </Typography>
    </div>
  );
};

export default PipeFlow;

import React from "react";
import Typography from "@mui/material/Typography";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AllConstants from "../data/AllConstants";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AllConversions from "../data/AllConversions";

const Conversions = () => {
  const displayConversions = () => {
    return AllConversions.map((item, i) => {
      return (
        <Card sx={{ minWidth: 275, mx: "4px", padding: "10px" }} key={i}>
          <Typography
            style={{
              fontWeight: "bold",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {item.icon} {item.name}
          </Typography>
          {item.conversions.map((element, i) => {
            return (
              <Typography
                key={i}
                style={{
                  fontSize: 16,
                  alignSelf: "flex-start",
                  textAlign: "left",
                }}
              >
                <CircleIcon sx={{ fontSize: 5 }} /> {element}
              </Typography>
            );
          })}
        </Card>
      );
    });
  };

  return (
    <div>
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        Common Unit Conversions
      </Typography>
      <Box className="conversions" style={{ display: "grid", gap: "10px" }}>
        {displayConversions()}
      </Box>
    </div>
  );
};

export default Conversions;

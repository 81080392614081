import "./App.css";
import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";

import Electronegativity from "./components/Electronegativity";
import Dashboard from "./components/Dashboard";
import ElectronConfiguration from "./components/ElectronConfiguration";
import PercentComposition from "./components/PercentComposition";
import AtomicMass from "./components/AtomicMass";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import MolarMass from "./components/MolarMass";
import Momentum from "./components/Momentum";
import FreeFall from "./components/FreeFall";
import PlanetWeight from "./components/PlanetWeight";
import StoppingDistance from "./components/StoppingDistance";
import Projectile from "./components/Projectile";
import FrictionLoss from "./components/FrictionLoss";
import HydraulicRadius from "./components/HydraulicRadius";
import PipeFlow from "./components/PipeFlow";
import WindLoad from "./components/WindLoad";
import OrificeFlow from "./components/OrificeFlow";
import HeatCapacity from "./components/HeatCapacity";
import IdealGas from "./components/IdealGas";
import ThermalResistance from "./components/ThermalResistance";
import Cooling from "./components/Cooling";
import ConductiveHeatTransfer from "./components/ConductiveHeatTransfer";
import Search from "./components/Search";
import Footer from "./components/Footer";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Conversions from "./components/Conversions";
import Constants from "./components/Constants";
import Equations from "./components/Equations";
import Calculators from "./components/Calculators";
import Privacy from "./components/Privacy";

function App() {
  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
  };

  return (
    <div className="App">
      <MathJaxContext config={config}>
        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/conversions" element={<Conversions />} />
            <Route path="/constants" element={<Constants />} />
            <Route path="/equations" element={<Equations />} />
            <Route path="/calculators" element={<Calculators />} />

            <Route path="/electronegativity" element={<Electronegativity />} />
            <Route
              path="/electron-configuration"
              element={<ElectronConfiguration />}
            />
            <Route
              path="/percent-composition"
              element={<PercentComposition />}
            />
            <Route path="/atomic-mass" element={<AtomicMass />} />
            <Route path="/molar-mass" element={<MolarMass />} />
            <Route path="/momentum" element={<Momentum />} />
            <Route path="/free-fall" element={<FreeFall />} />
            <Route path="/planet-weight" element={<PlanetWeight />} />
            <Route path="/stopping-distance" element={<StoppingDistance />} />
            <Route path="/projectile" element={<Projectile />} />
            <Route path="/friction-loss" element={<FrictionLoss />} />
            <Route path="/hydraulic-radius" element={<HydraulicRadius />} />
            <Route path="/pipe-flow" element={<PipeFlow />} />
            <Route path="/wind-load" element={<WindLoad />} />
            <Route path="/orifice-flow" element={<OrificeFlow />} />
            <Route path="/heat-capacity" element={<HeatCapacity />} />
            <Route path="/ideal-gas" element={<IdealGas />} />
            <Route path="/thermal-resistance" element={<ThermalResistance />} />
            <Route path="/cooling" element={<Cooling />} />
            <Route path="/conduction" element={<ConductiveHeatTransfer />} />
            <Route path="/search" element={<Search />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
          {/* <Sidebar /> */}

          <Footer />
        </BrowserRouter>
      </MathJaxContext>
    </div>
  );
}

export default App;

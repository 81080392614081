import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const ConductiveHeatTransfer = () => {
  const [area, setArea] = useState(20);
  const [areaUnit, setAreaUnit] = useState("square meters (m2)");

  const [coldTemp, setColdTemp] = useState(20);
  const [coldTempUnit, setColdTempUnit] = useState("Celsius (C)");

  const [hotTemp, setHotTemp] = useState(80);
  const [hotTempUnit, setHotTempUnit] = useState("Celsius (C)");

  const [time, setTime] = useState(5);
  const [timeUnit, setTimeUnit] = useState("seconds (s)");

  const [thickness, setThickness] = useState(0.05);
  const [thicknessUnit, setThicknessUnit] = useState("meters (m)");

  const [material, setMaterial] = useState("Concrete");

  const [heatTransfer, setHeatTransfer] = useState();

  const distanceUnits = [
    "meters (m)",
    "feet (ft)",
    "inches (in)",
    "centimeters (cm)",
  ];

  const areaUnits = [
    "square meters (m2)",
    "square centimeters (cm2)",
    "square inches (in2)",
    "square feet (ft2)",
  ];

  const timeUnits = ["seconds (s)", "minutes (min)", "hours (hr)"];

  const tempUnits = ["Fahrenheit (F)", "Celsius (C)", "Kelvin (K)"];

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  const convertArea = (area, unit) => {
    if (unit === "square feet (ft2)") {
      return area / 10.764;
    } else if (unit === "square inches (in2)") {
      return area / 1550;
    } else if (unit === "square centimeters (cm2)") {
      return area / 10000;
    }
    return area;
  };

  const convertTemp = (t, unit) => {
    if (unit === "Fahrenheit (F)") {
      return (9 / 5) * (+t - 32);
    } else if (unit === "Kelvin (K)") {
      return +t - 273;
    }
    return t;
  };

  const convertTime = (t, unit) => {
    if (unit === "minutes (min)") {
      return t * 60;
    } else if (unit === "hours (hr)") {
      return t * 3600;
    }
    return t;
  };

  const thermalConductivity = useMemo(() => {
    return {
      Air: 0.024,
      Water: 0.6,
      Aluminum: 205,
      Copper: 401,
      Iron: 80.2,
      Lead: 35.3,
      Glass: 1.0,
      Wood: 0.13,
      Brick: 0.6,
      Concrete: 1.7,
      "Stainless Steel": 16.3,
      Silver: 429,
      Gold: 314,
      "Plastic (Polyethylene)": 0.36,
      "Plastic (Polypropylene)": 0.15,
      "Plastic (PVC)": 0.19,
      "Plastic (Polystyrene)": 0.03,
      "Plastic (Polyurethane)": 0.02,
    };
  }, []);

  useEffect(() => {
    const cTime = convertTime(time, timeUnit);
    const cArea = convertArea(area, areaUnit);
    const cHotT = convertTemp(hotTemp, hotTempUnit);
    const cColdT = convertTemp(coldTemp, coldTempUnit);
    const cThickness = convertDistance(thickness, thicknessUnit);
    const heatTrans =
      (thermalConductivity[material] * cArea * cTime * (cHotT - cColdT)) /
      cThickness;
    setHeatTransfer(heatTrans.toFixed(3));
  }, [
    time,
    timeUnit,
    area,
    areaUnit,
    hotTemp,
    hotTempUnit,
    coldTemp,
    coldTempUnit,
    thickness,
    thicknessUnit,
    thermalConductivity,
    material,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Conductive Heat Transfer</Typography>
        <Typography variant="h6">
          Determine how much heat is transferred by conduction.{" "}
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="conduction-input">
            <TextField
              label="Cross-sectional Area"
              className="text-field"
              id="area"
              variant="standard"
              type="number"
              value={area}
              onChange={(e) => {
                setArea(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="area-unit"
              value={areaUnit}
              variant="standard"
              onChange={(e) => {
                setAreaUnit(e.target.value);
              }}
            >
              {areaUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="conduction-input">
            <TextField
              label="Thickness"
              className="text-field"
              id="thickness"
              variant="standard"
              type="number"
              value={thickness}
              onChange={(e) => {
                setThickness(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="thickness-unit"
              value={thicknessUnit}
              variant="standard"
              onChange={(e) => {
                setThicknessUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="conduction-input">
            <TextField
              label="Cold Temp"
              className="text-field"
              id="cold-temp"
              variant="standard"
              type="number"
              value={coldTemp}
              onChange={(e) => {
                setColdTemp(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="cold-temp-unit"
              value={coldTempUnit}
              variant="standard"
              onChange={(e) => {
                setColdTempUnit(e.target.value);
              }}
            >
              {tempUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="conduction-input">
            <TextField
              label="Hot Temperature"
              className="text-field"
              id="hot-temp"
              variant="standard"
              type="number"
              value={hotTemp}
              onChange={(e) => {
                setHotTemp(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="hot-temp-unit"
              value={hotTempUnit}
              variant="standard"
              onChange={(e) => {
                setHotTempUnit(e.target.value);
              }}
            >
              {tempUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="conduction-input">
            <TextField
              label="Time"
              className="text-field"
              id="time"
              variant="standard"
              type="number"
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
            <Select
              className="select-field"
              id="time-unit"
              value={timeUnit}
              variant="standard"
              onChange={(e) => {
                setTimeUnit(e.target.value);
              }}
            >
              {timeUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box>
            <FormControl>
              <FormLabel id="condition">Material</FormLabel>
              <Select
                className="select-field"
                id="material"
                value={material}
                variant="standard"
                onChange={(e) => {
                  setMaterial(e.target.value);
                }}
              >
                {Object.keys(thermalConductivity).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Typography variant="body">
          Heat Transfer (J):{" "}
          <span className="electro">{heatTransfer ? heatTransfer : null}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Conductive Transfer Explained</Typography>
      <Typography paragraph>
        Conductive heat transfer is a fundamental mode of heat transfer that
        occurs when thermal energy flows through a solid material due to the
        direct interaction of neighboring atoms and molecules. In solids, heat
        is transferred through the vibration and collision of atoms within the
        lattice structure. The efficiency of this process depends on the
        material's thermal conductivity, which is a property defining its
        ability to conduct heat.
      </Typography>
      <Typography paragraph>
        The mechanism of conductive heat transfer involves the exchange of
        kinetic energy between adjacent particles. At the microscopic level,
        high-energy particles transfer some of their energy to lower-energy
        particles through collisions, leading to the propagation of heat. This
        process is characterized by Fourier's Law of Heat Conduction, which
        states that the rate of heat transfer is directly proportional to the
        temperature gradient across the material and inversely proportional to
        its thermal resistance.
      </Typography>
      <Typography paragraph>
        Newton's Law of Cooling is expressed as:
      </Typography>

      <MathJax>{"${q} = {-k} {\\Delta T \\over \\Delta x}$"}</MathJax>

      <Typography paragraph>where</Typography>
      <Typography>
        q is the flow of heat via conduction through a slab
      </Typography>

      <Typography paragraph>
        k is the thermal conductivity of the material and it depends primarily
        on the physical composition of the material
      </Typography>
      <Typography paragraph>
        &Delta; T is the temperature difference maintained across the slab
      </Typography>
      <Typography paragraph>&Delta; x is the thickness of the slab</Typography>

      <Typography paragraph>
        Materials with high thermal conductivity, such as metals, are excellent
        conductors of heat because they allow for efficient transmission of
        thermal energy. In contrast, insulating materials, like rubber or
        certain polymers, have low thermal conductivity and impede the flow of
        heat. Engineers and scientists often consider the thermal properties of
        materials when designing systems to control heat transfer, such as in
        building insulation, electronic devices, or industrial processes.
      </Typography>
      <Typography paragraph>
        Understanding conductive heat transfer is crucial in various fields, as
        it plays a significant role in determining the thermal performance of
        materials and the overall efficiency of heat exchange processes.
        Engineers and researchers continually explore ways to optimize and
        manipulate conductive heat transfer for applications ranging from
        energy-efficient building design to the development of advanced
        electronic devices.
      </Typography>
    </div>
  );
};

export default ConductiveHeatTransfer;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TriangularDiagram from "../assets/triangular.png";
import RectangularDiagram from "../assets/rectangular.png";
import CircularDiagram from "../assets/circular.png";
import { MathJax } from "better-react-mathjax";

const HydraulicRadius = () => {
  const shapes = ["rectangular", "circular", "triangular"];
  const [shape, setShape] = useState("rectangular");
  const [height, setHeight] = useState(5);
  const [heightUnit, setHeightUnit] = useState("meters (m)");
  const [width, setWidth] = useState(5);
  const [widthUnit, setWidthUnit] = useState("meters (m)");
  const [radius, setRadius] = useState(5);
  const [radiusUnit, setRadiusUnit] = useState("meters (m)");

  const [area, setArea] = useState(0);
  const [wettedPerimeter, setWettedPerimeter] = useState(0);
  const [hydRad, setHydRad] = useState(0);

  const distanceUnits = [
    "meters (m)",
    "feet (ft)",
    "inches (in)",
    "centimeters (cm)",
  ];

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  useEffect(() => {
    if (shape === "rectangular") {
      const convertedHeight = convertDistance(height, heightUnit);
      const convertedWidth = convertDistance(width, widthUnit);
      setArea(convertedHeight * convertedWidth);
      setWettedPerimeter(+convertedWidth + 2 * +convertedHeight);
      setHydRad(area / wettedPerimeter);
    } else if (shape === "circular") {
      const convertedRadius = parseFloat(convertDistance(radius, radiusUnit));
      setArea(3.14 * convertedRadius ** 2);
      setWettedPerimeter(2 * 3.14 * convertedRadius);
      setHydRad(area / wettedPerimeter);
    } else if (shape === "triangular") {
      const convertedHeight = convertDistance(height, heightUnit);
      const convertedWidth = convertDistance(width, widthUnit);
      setArea((convertedHeight * convertedWidth) / 2);
      const slope = area / convertedHeight ** 2;
      setWettedPerimeter(2 * convertedHeight * Math.sqrt(1 + slope ** 2));
      setHydRad(area / wettedPerimeter);
    }
  }, [
    shape,
    height,
    heightUnit,
    width,
    widthUnit,
    area,
    wettedPerimeter,
    radius,
    radiusUnit,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Hydraulic Radius</Typography>
        <Typography variant="h6">
          Calculate the hydraulic radius for different pipe shapes & dimensions.{" "}
        </Typography>

        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="hydraulic-radius-input">
            <FormControl>
              <FormLabel id="condition">Pipe Shape</FormLabel>
              <Select
                className="select-field"
                id="shape"
                value={shape}
                variant="standard"
                onChange={(e) => {
                  setShape(e.target.value);
                }}
              >
                {shapes.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {shape === "rectangular" ? (
            <div>
              <img
                className="diagram-image"
                src={RectangularDiagram}
                alt="diagram"
              />
              <Box className="hydraulic-radius-input">
                <TextField
                  label="Height"
                  className="text-field"
                  id="height"
                  variant="standard"
                  type="number"
                  defaultValue={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
                <Select
                  className="select-field"
                  id="height-unit"
                  value={heightUnit}
                  variant="standard"
                  onChange={(e) => {
                    setHeightUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box className="hydraulic-radius-input">
                <TextField
                  label="Width"
                  className="text-field"
                  id="width"
                  variant="standard"
                  type="number"
                  defaultValue={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
                <Select
                  className="select-field"
                  id="width-unit"
                  value={widthUnit}
                  variant="standard"
                  onChange={(e) => {
                    setWidthUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : shape === "circular" ? (
            <div>
              <img
                className="diagram-image"
                src={CircularDiagram}
                alt="diagram"
              />

              <Box className="hydraulic-radius-input">
                <TextField
                  label="Radius"
                  className="text-field"
                  id="radius"
                  variant="standard"
                  type="number"
                  defaultValue={radius}
                  onChange={(e) => setRadius(e.target.value)}
                />
                <Select
                  className="select-field"
                  id="radius-unit"
                  value={radiusUnit}
                  variant="standard"
                  onChange={(e) => {
                    setRadiusUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : shape === "triangular" ? (
            <div>
              <img
                className="diagram-image"
                src={TriangularDiagram}
                alt="diagram"
              />

              <Box className="hydraulic-radius-input">
                <TextField
                  label="Height"
                  className="text-field"
                  id="height"
                  variant="standard"
                  type="number"
                  defaultValue={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
                <Select
                  className="select-field"
                  id="height-unit"
                  value={heightUnit}
                  variant="standard"
                  onChange={(e) => {
                    setHeightUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box className="hydraulic-radius-input">
                <TextField
                  label="Width"
                  className="text-field"
                  id="width"
                  variant="standard"
                  type="number"
                  defaultValue={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
                <Select
                  className="select-field"
                  id="width-unit"
                  value={widthUnit}
                  variant="standard"
                  onChange={(e) => {
                    setWidthUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : null}
        </Box>

        <Typography variant="body">
          Area (m<sup>2</sup>):{" "}
          <span className="electro">{area.toFixed(3)}</span>
        </Typography>

        <Typography variant="body">
          Wetted Perimeter (m):{" "}
          <span className="electro">{wettedPerimeter.toFixed(3)}</span>
        </Typography>

        <Typography variant="body">
          Hydraulic Radius (m):{" "}
          <span className="electro">{hydRad.toFixed(3)}</span>
        </Typography>
      </Box>
      <Typography variant="h3">Hydraulic Radius Explained</Typography>
      <Typography paragraph>
        The hydraulic radius is a crucial parameter in fluid mechanics that
        characterizes the efficiency of a pipe or channel in transporting water.
        It is defined as the ratio of the cross-sectional area of the flow to
        the wetted perimeter. The hydraulic radius is a key factor in
        determining the frictional losses and the velocity distribution of
        flowing water.
      </Typography>
      <Typography paragraph>
        For circular pipes, the hydraulic radius R is simply the radius r of the
        pipe. Mathematically, R is given by R = r. The cross-sectional area A of
        a circular pipe is given by
      </Typography>
      <MathJax>{"${A=\\pi r^2}$"}</MathJax>
      <Typography>and the wetted perimeter P is given by</Typography>
      <MathJax>{"${P=2 \\pi r}$"}</MathJax>
      <Typography paragraph>
        For triangular pipes, the hydraulic radius is calculated differently
        based on the type of triangle. If the triangle is equilateral (all sides
        and angles are equal), the hydraulic radius R is given by
      </Typography>
      <MathJax>{"${R} = {h \\over 2 \\sqrt3}$"}</MathJax>
      <Typography paragraph>
        where h is the height of the equilateral triangle. The cross-sectional
        area A is given by
      </Typography>
      <MathJax>{"${A = {\\sqrt 3 \\over 4}  h^2}$"}</MathJax>
      <Typography paragraph>and the wetted perimeter P is given by </Typography>

      <MathJax>{"${P = 3h}$"}</MathJax>
      <Typography paragraph>
        For other types of triangles, the hydraulic radius formula may vary
        based on the specific dimensions and geometry.
      </Typography>
      <Typography>
        For rectangular pipes, the hydraulic radius R is expressed as
      </Typography>
      <MathJax>{"${R} = {h \\over 2}$"}</MathJax>
      <Typography paragraph>
        where h is the height of the rectangular pipe. The cross-sectional area
        A is
      </Typography>

      <MathJax>{"${A = b h}$"}</MathJax>

      <Typography paragraph>
        where b is the base width, and the wetted perimeter P is given by
      </Typography>
      <MathJax>{"${P = b+2h}$"}</MathJax>

      <Typography paragraph>
        The hydraulic radius is essential in calculations related to open
        channel flow, such as Manning's equation, which relates flow velocity,
        channel slope, hydraulic radius, and the Manning roughness coefficient
        to determine the flow rate in an open channel. Engineers use these
        formulas to design efficient and effective water transport systems,
        ensuring optimal hydraulic performance and minimizing energy losses in
        pipes and channels of different shapes and dimensions.
      </Typography>
    </div>
  );
};

export default HydraulicRadius;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { MathJax } from "better-react-mathjax";

const Projectile = () => {
  const [vel, setVel] = useState(50);
  const [angle, setAngle] = useState(40);
  const [initHeight, setInitHeight] = useState(5);
  const [velUnit, setVelUnit] = useState("meters per second (m/s)");
  const [angleUnit, setAngleUnit] = useState("degrees");
  const [heightUnit, setHeightUnit] = useState("meters (m)");

  const g = 9.806;

  const [time, setTime] = useState(null);
  const [range, setRange] = useState(null);
  const [maxHeight, setMaxHeight] = useState(null);

  const speedUnits = [
    "meters per second (m/s)",
    "kilometers per hour (km/h)",
    "feet per second (ft/s)",
  ];

  const distanceUnits = ["meters (m)", "feet (ft)", "miles (mi)"];

  const angleUnits = ["degrees", "radians"];

  const convertSpeed = (speed, unit) => {
    if (unit === "kilometers per hour (km/h)") {
      return speed / 3.6;
    } else if (unit === "feet per second (ft/s)") {
      return speed / 3.281;
    }
    return speed;
  };

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "miles (mi)") {
      return dist / 1609;
    }
    return dist;
  };

  const convertAngle = (angle, unit) => {
    if (unit === "degrees") {
      return (+angle * 3.14) / 180;
    }
    return angle;
  };

  useEffect(() => {
    const convertedHeight = convertDistance(initHeight, heightUnit);
    const convertedVel = convertSpeed(vel, velUnit);
    const convertedAngle = convertAngle(angle, angleUnit);
    const horizVel = convertedVel * Math.cos(convertedAngle);
    const vertVel = convertedVel * Math.sin(convertedAngle);

    const timePassed =
      (vertVel + Math.sqrt(vertVel ** 2 + 2 * g * convertedHeight)) / g;
    setTime(timePassed.toFixed(3));
    const horizRange =
      (horizVel / g) *
      (vertVel + Math.sqrt(vertVel ** 2 + 2 * g * convertedHeight));
    setRange(horizRange.toFixed(3));
    const maxHeightReached = convertedHeight + vertVel ** 2 / (2 * g);
    setMaxHeight(parseFloat(maxHeightReached).toFixed(3));
  }, [initHeight, heightUnit, vel, velUnit, angle, angleUnit]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Projectile</Typography>
        <Typography variant="h6">
          Evaluate the trajectory of a projectile based on various initial
          conditions.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="projectile-input">
            <TextField
              label="Initial Velocity"
              className="text-field"
              id="vel"
              variant="standard"
              type="number"
              defaultValue={vel}
              onChange={(e) => setVel(e.target.value)}
            />
            <Select
              className="select-field"
              id="vel-unit"
              value={velUnit}
              variant="standard"
              onChange={(e) => {
                setVelUnit(e.target.value);
              }}
            >
              {speedUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="projectile-input">
            <TextField
              label="Launch Angle"
              className="text-field"
              id="angle"
              variant="standard"
              type="number"
              defaultValue={angle}
              onChange={(e) => setAngle(e.target.value)}
            />
            <Select
              className="select-field"
              id="angle-unit"
              value={angleUnit}
              variant="standard"
              onChange={(e) => {
                setAngleUnit(e.target.value);
              }}
            >
              {angleUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="projectile-input">
            <TextField
              label="Initial Height"
              className="text-field"
              id="height"
              variant="standard"
              type="number"
              defaultValue={initHeight}
              onChange={(e) => setInitHeight(e.target.value)}
            />
            <Select
              className="select-field"
              id="height-unit"
              value={heightUnit}
              variant="standard"
              onChange={(e) => {
                setHeightUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>

        <Typography variant="body">
          Time (s): <span className="electro"> {time ? time : null}</span>
        </Typography>

        <Typography variant="body">
          Horizontal Range (m):{" "}
          <span className="electro"> {range ? range : null}</span>
        </Typography>

        <Typography variant="body">
          Maximum Height (m):{" "}
          <span className="electro"> {maxHeight ? maxHeight : null}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Projectile Physics Explained</Typography>
      <Typography paragraph>
        Projectile motion is a fascinating aspect of classical mechanics that
        describes the motion of an object thrown into the air, experiencing only
        the force of gravity and air resistance (if present). This type of
        motion is characterized by the object following a curved trajectory
        under the influence of a constant gravitational acceleration. The
        physics of projectile motion can be analyzed using principles from
        kinematics and vector analysis.
      </Typography>
      <Typography paragraph>
        The key components of projectile motion include the launch angle,
        initial velocity, time of flight, range, and maximum height. The motion
        can be broken down into horizontal and vertical components. The
        horizontal motion is uniform and follows the laws of constant velocity,
        while the vertical motion is influenced by gravity and follows the laws
        of constant acceleration. The independence of these two components
        allows for a comprehensive analysis of projectile motion.
      </Typography>
      <Typography paragraph>
        For the horizontal component of the motion, the following equations
        apply:
      </Typography>

      <MathJax>{"${v_{0x} = v_x}$"}</MathJax>
      <MathJax>{"${x_{0} = x_0 + v_x t}$"}</MathJax>
      <Typography paragraph>
        For the vertical component of the motion, the following equations apply:
      </Typography>

      <MathJax>{"${y} = y_0 + {1 \\over2}( {v_{0y} + v_y}) t$"}</MathJax>
      <MathJax>{"${v_y = v_{0y} - gt}$"}</MathJax>
      <MathJax>{"${y} = {y_0 + v_{0y}t - {{1 \\over 2} gt^2}}$"}</MathJax>
      <MathJax>{"${v_y^2} = {v_{0y}^2 - 2g (y - y_0)}$"}</MathJax>
      <Typography paragraph>
        In the above cases, x and y refer to the positions along the horizontal
        and vertical orientations, respectively. v represents the velocity, t
        represents the time elapsed and g represents the graviational
        acceleration. A subscript of 0 indicates an initial condition.
      </Typography>
      <Typography paragraph>
        The trajectory of a projectile is a parabola, and its shape is
        determined by the interplay of the horizontal and vertical motions. The
        launch angle significantly influences the range and height of the
        projectile. For a given initial velocity, the range is maximized at a
        launch angle of 45 degrees in the absence of air resistance.
      </Typography>
      <Typography paragraph>
        The understanding of projectile motion is crucial in various fields,
        including sports, physics, engineering, and astronomy. The precise
        analysis of a projectile's motion allows for accurate predictions and
        optimizations, such as determining the ideal launch angle for a
        projectile to achieve maximum range or studying the motion of celestial
        bodies influenced by gravitational forces.
      </Typography>
    </div>
  );
};

export default Projectile;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import OrificeDiagram from "../assets/orifice.png";
import { MathJax } from "better-react-mathjax";

const OrificeFlow = () => {
  const [diameter, setDiameter] = useState(5);
  const [diameterUnit, setDiameterUnit] = useState("meters (m)");
  const [head, setHead] = useState(10);
  const [headUnit, setHeadUnit] = useState("meters (m)");

  const [coefficient, setCoefficient] = useState(0.8);

  const [discharge, setDischarge] = useState(0);

  const distanceUnits = [
    "meters (m)",
    "centimeters (cm)",
    "feet (ft)",
    "inches (in)",
  ];

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  useEffect(() => {
    const convertedDiameter = convertDistance(diameter, diameterUnit);
    const convertedHead = convertDistance(head, headUnit);
    const flow =
      coefficient *
      (convertedDiameter / 2) ** 2 *
      3.14 *
      Math.sqrt(2 * 9.81 * convertedHead);
    setDischarge(flow.toFixed(3));
  }, [diameter, diameterUnit, head, headUnit, coefficient]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Orifice Flow</Typography>
        <Typography variant="h6">
          Determine the discharge flow rate of water based on system geometry.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="orifice-input">
            <TextField
              label="Diameter of Orifice"
              className="text-field"
              id="diam"
              variant="standard"
              type="number"
              defaultValue={diameter}
              onChange={(e) => setDiameter(e.target.value)}
            />
            <Select
              className="select-field"
              id="diam-unit"
              value={diameterUnit}
              variant="standard"
              onChange={(e) => {
                setDiameterUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="orifice-input">
            <TextField
              label="Center Line Head"
              className="text-field"
              id="head"
              variant="standard"
              type="number"
              defaultValue={head}
              onChange={(e) => setHead(e.target.value)}
            />
            <Select
              className="select-field"
              id="head-unit"
              value={headUnit}
              variant="standard"
              onChange={(e) => {
                setHeadUnit(e.target.value);
              }}
            >
              {distanceUnits.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box className="orifice-input">
            <FormControl>
              <FormLabel id="condition">Coefficient of Discharge</FormLabel>
              <TextField
                className="text-field"
                id="coeff"
                variant="standard"
                type="number"
                defaultValue={coefficient}
                onChange={(e) => setCoefficient(e.target.value)}
              />
            </FormControl>
          </Box>
          <img
            style={{ marginTop: "10px" }}
            src={OrificeDiagram}
            alt="orifice-diagram"
          />
        </Box>
        <Typography variant="body">
          Discharge Flow Rate (m<sup>3</sup>/s):{" "}
          <span className="electro"> {discharge ? discharge : null}</span>
        </Typography>
      </Box>
      <Typography variant="h3">Orifice Flow Explained</Typography>
      <Typography paragraph>
        Calculating orifice flow discharge is a fundamental aspect of fluid
        mechanics and is commonly employed in various engineering applications,
        such as in the design of hydraulic systems, flow measurement devices,
        and water distribution systems. The orifice equation is used to estimate
        the flow rate through an orifice based on the head of water, the
        diameter of the orifice, and the discharge coefficient.
      </Typography>
      <Typography paragraph>
        The basic form of the orifice equation is given by:
      </Typography>
      <MathJax>{"${Q} = {C_d} {A} {\\sqrt{2gh} }$"}</MathJax>

      <Typography>where</Typography>
      <Typography>Q is the discharge flow rate</Typography>

      <Typography>
        C<sub>d</sub> is the discharge coefficient
      </Typography>
      <Typography>A is the cross-sectional area of the orifice</Typography>
      <Typography>g is the acceleration of gravity</Typography>
      <Typography>
        h is the head of water above the center of the orifice
      </Typography>

      <Typography paragraph>
        The discharge coefficient C<sub>d</sub> is a dimensionless factor that
        accounts for the efficiency of the orifice in converting potential
        energy to kinetic energy. It is influenced by the shape and smoothness
        of the orifice, the approach velocity of the fluid, and other factors
        affecting flow patterns.
      </Typography>
      <Typography paragraph>
        The cross-sectional area of the orifice A is calculated based on the
        orifice diameter d as
      </Typography>

      <MathJax>{"${A} = {\\pi d^2 \\over 4}$"}</MathJax>
      <Typography paragraph>
        This equation is derived from Bernoulli's equation and Torricelli's law,
        assuming ideal conditions and neglecting losses due to friction and
        other factors. The square root relationship with the head of water
        demonstrates the dependence of flow rate on the square root of the
        potential energy available.
      </Typography>
      <Typography paragraph>
        It's important to note that the discharge coefficient is often
        experimentally determined for a specific orifice under particular flow
        conditions. The actual value of the discharge coefficient can vary based
        on factors such as the type of orifice, the Reynolds number of the flow,
        and the orifice's geometry.
      </Typography>
      <Typography paragraph>
        Engineers use the orifice equation to design and analyze systems where
        accurate flow rate measurements are crucial. Orifices are commonly used
        in flow meters, control valves, and other hydraulic devices, and
        understanding the relationship between head, orifice diameter, and
        discharge coefficient is essential for optimizing system performance.
      </Typography>
    </div>
  );
};

export default OrificeFlow;

import React from "react";
import Typography from "@mui/material/Typography";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AllConstants from "../data/AllConstants";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AllEquations from "../data/AllEquations";

const Equations = () => {
  const displayEquations = () => {
    return AllEquations.map((item, i) => {
      return (
        <Card sx={{ minWidth: 275, mx: "4px", padding: "10px" }} key={i}>
          <Typography
            style={{
              fontWeight: "bold",
              alignSelf: "center",
            }}
          >
            {item.name}
          </Typography>
          <Typography style={{ alignSelf: "center" }}>
            {item.equation}
          </Typography>

          <Typography style={{ fontSize: 16, textAlign: "left" }}>
            <CircleIcon sx={{ fontSize: 7 }} />
            Application: {item.application}
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              textAlign: "left",
            }}
          >
            <CircleIcon sx={{ fontSize: 7 }} />
            Assumptions: {item.assumptions}
          </Typography>

          <Typography style={{ fontSize: 16, textAlign: "left" }}>
            <CircleIcon sx={{ fontSize: 7 }} />
            Usage: {item.usage}
          </Typography>
        </Card>
      );
    });
  };

  return (
    <div>
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        Essential Equations
      </Typography>
      <Box style={{ display: "grid", gap: "10px" }} className="equations">
        {displayEquations()}
      </Box>
    </div>
  );
};

export default Equations;

import React from "react";
import Typography from "@mui/material/Typography";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import { Box } from "@mui/material";

const Calculators = () => {
  const displayAllCalcs = CalculatorData.map((calc, i) => {
    return <DisplayCard key={i} calc={calc} />;
  });

  return (
    <div>
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        All Calculators
      </Typography>
      <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {displayAllCalcs}
      </Box>{" "}
    </div>
  );
};

export default Calculators;

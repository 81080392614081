import React, { useState } from "react";
import electronData from "../data/ElectronData";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

const ElectronConfiguration = () => {
  const [element, setElement] = useState("O");

  const getData = () => {
    return electronData[element];
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Electron Configuration </Typography>
        <Typography variant="h6">
          What is the distribution of electrons in any given element?
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="element-one">Element</InputLabel>
          <Select
            className="select-field"
            labelId="element-one"
            variant="standard"
            id="element-one"
            value={element}
            label="Element"
            onChange={(e) => setElement(e.target.value)}
          >
            {Object.entries(electronData).map(([key, value]) => {
              return <MenuItem value={key}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <Typography variant="body1" gutterBottom>
          Atomic Number:{" "}
          <span className="electro">{getData().atomicNumber}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Atomic Mass: <span className="electro">{getData().atomicMass}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Electron Configuration:{" "}
          <span className="electro">{getData().electronConfig}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Valence Electrons:{" "}
          <span className="electro">{getData().valenceElectrons}</span>
        </Typography>
      </Box>

      <Typography variant="h3">Electron Configuration Explained</Typography>
      <Typography paragraph>
        Electron configuration is a representation of how electrons are
        distributed among the energy levels, or shells, in an atom. It describes
        the specific arrangement of electrons in an atom, detailing which energy
        levels contain electrons and how many electrons are in each level. The
        electron configuration is crucial for understanding the chemical
        behavior and properties of elements.
      </Typography>
      <Typography paragraph>
        In an atom, electrons occupy energy levels or shells labeled with the
        principal quantum number (n). The first energy level (n = 1) is closest
        to the nucleus, followed by subsequent levels (n = 2, 3, 4, and so on)
        that are progressively farther away. Each energy level can accommodate a
        certain maximum number of electrons, given by the formula 2n^2. The
        first energy level (n = 1) can hold up to 2 electrons, the second (n =
        2) can hold up to 8 electrons, and so forth.
      </Typography>
      <Typography paragraph>
        The electron configuration is often written using a notation that
        includes the principal quantum number and the subshell designation. The
        subshells are denoted by the letters s, p, d, and f, each corresponding
        to a different orbital shape. For example, the electron configuration of
        carbon (C) is 1s² 2s² 2p², indicating that there are two electrons in
        the first energy level (1s²) and four electrons in the second energy
        level (2s² 2p²).
      </Typography>
      <Typography paragraph>
        Valence electrons are the electrons in the outermost energy level of an
        atom. These electrons are crucial in determining the chemical properties
        and reactivity of an element. The number of valence electrons
        corresponds to the group number of an element in the periodic table. For
        example, elements in Group 1 (such as hydrogen and lithium) have one
        valence electron, while elements in Group 18 (such as helium and neon)
        have eight valence electrons.
      </Typography>
      <Typography paragraph>
        Understanding the electron configuration and valence electrons is
        essential for predicting how atoms will interact to form chemical bonds.
        Elements with similar electron configurations often exhibit similar
        chemical behaviors, and the arrangement of electrons in different
        orbitals influences the formation of compounds and the sharing or
        transfer of electrons in chemical reactions. The concept of electron
        configuration is a fundamental aspect of chemistry that helps explain
        the diversity and predictability of the chemical properties of elements.
      </Typography>
    </div>
  );
};

export default ElectronConfiguration;

import React from "react";
import Typography from "@mui/material/Typography";

const Privacy = () => {
  return (
    <div>
      <Typography variant="h3">Privacy Policy for Calculo App</Typography>
      <Typography paragraph>Effective Date: April 25, 2024</Typography>
      <Typography paragraph>
        Thank you for using Calculo, the app that brings engineering calculators
        to your fingertips. Your privacy is important to us. This Privacy Policy
        explains how we collect, use, disclose, and protect your information
        when you use our app.
      </Typography>

      <Typography paragraph>
        1. Information We Collect:
        <ul>
          <li>
            User Names: We collect user names to personalize your experience
            within the app.
          </li>
          <li>
            Email Addresses: We collect email addresses to communicate with you
            and to allow you to recover your account if needed.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        2. How We Use Your Information:
        <ul>
          <li>
            Personalization: We use user names to personalize your experience
            and to address you within the app.
          </li>
          <li>
            Communication: We use email addresses to respond to your inquiries.
          </li>
          <li>
            Analytical Purposes: We may analyze user data to understand usage
            patterns and improve the app's functionality.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        3. Legal Compliance: We may disclose your personal information if
        required by law, regulation, or legal process, or in response to a valid
        request from law enforcement authorities or other government officials.
      </Typography>
      <Typography paragraph>
        3. Data Sharing: We do not sell, trade, or otherwise transfer your
        information to outside parties. We may share your information with
        trusted third-party service providers who assist us in operating our app
        or servicing you.
      </Typography>
      <Typography paragraph>
        4. Business Transfer: In the event of a merger, acquisition, or sale of
        all or a portion of our assets, your personal information may be
        transferred to the acquiring entity. We will notify you via prominent
        notice within the App or through other communication channels before
        your personal information becomes subject to a different privacy policy.
      </Typography>
      <Typography paragraph>
        5. Data Security: We implement reasonable security measures to protect
        your information against unauthorized access, alteration, disclosure, or
        destruction. Despite our efforts, no method of transmission over the
        internet or electronic storage is completely secure. Therefore, we
        cannot guarantee absolute security.
      </Typography>
      <Typography paragraph>
        6. Children's Privacy: Calculo is not directed to individuals under the
        age of 13. We do not knowingly collect personal information from
        children under 13. If you are a parent or guardian and become aware that
        your child has provided us with personal information, please contact us.
        If we become aware that we have collected personal information from
        children under 13 without verification of parental consent, we will take
        steps to remove that information from our servers.
      </Typography>
      <Typography paragraph>
        7. Changes to This Privacy Policy: We reserve the right to update or
        change our Privacy Policy at any time. Any changes will be effective
        immediately upon posting the revised Privacy Policy. - We encourage you
        to review this Privacy Policy periodically for any changes. Your
        continued use of the app after any changes to the Privacy Policy will
        constitute your acceptance of such changes.
      </Typography>
      <Typography paragraph>
        8. Contact Us: If you have any questions or concerns about this Privacy
        Policy, please contact us at apps.calculo@gmail.com.
      </Typography>
      <Typography paragraph>
        By using Calculo, you consent to the terms of this Privacy Policy.
      </Typography>
      <Typography paragraph>End of Privacy Policy</Typography>
    </div>
  );
};
export default Privacy;

import React, { useEffect, useState } from "react";
import CalculatorData from "../assets/CalculatorData";
import DisplayCard from "./DisplayCard";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typewriter from "../utils/Typewriter";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import CarImage from "../assets/car.jpg";
import PlanetImage from "../assets/planet.jpg";
import ChemImage from "../assets/chemistry.jpg";
import HotImage from "../assets/hot.jpg";
import PipeImage from "../assets/pipe.jpg";
import TennisImage from "../assets/tennis.jpg";
import OutboundIcon from "@mui/icons-material/Outbound";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import EngineeringIcon from "@mui/icons-material/Engineering";
import RepeatIcon from "@mui/icons-material/Repeat";
import ScienceIcon from "@mui/icons-material/Science";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const displayAllCalcs = CalculatorData.map((calc, i) => {
    return <DisplayCard key={i} calc={calc} />;
  });

  const featuredCalcs = [
    {
      name: "Stopping Distance",
      url: "/stopping-distance",
      image: CarImage,
      description:
        "Stopping Distance Saga: where speed demons meet their match in a battle of brakes and brainpower!",
    },
    {
      name: "Planet Weights",
      url: "/planet-weight",
      image: PlanetImage,
      description:
        "Gravity's the ultimate scale shifter! Let's take a trip to Mars, shall we?",
    },
    {
      name: "Bond Type",
      url: "/electronegativity",
      image: ChemImage,
      description:
        "Bonding Bingo: where electronegativity sparks relationships that make or break!",
    },
    {
      name: "Pipe Flow",
      url: "/pipe-flow",
      image: PipeImage,
      description: "Whoosh! Let's explore fluid movement through pipes.",
    },
    {
      name: "Ball Projectile",
      url: "/projectile",
      image: TennisImage,
      description:
        "Projectile Physics! Sometimes, the laws of gravity just want to see things fly!",
    },
    {
      name: "Hot Drink",
      url: "/cooling",
      image: HotImage,
      description:
        "Caffeine Cooling Chronicles: where patience is brewed one sip at a time!",
    },
  ];

  const displayFeatured = () => {
    return featuredCalcs.map((item, i) => {
      return <DisplayCard calc={item} />;
    });
  };

  const tools = [
    {
      name: "Engineering Constants",
      url: "constants",
      description:
        "A list of the top 30 physics and mathematics constants along with their values and brief descriptions",
      icon: <EngineeringIcon />,
    },
    {
      name: "Unit Conversions",
      url: "conversions",
      description:
        "A handy list of unit conversions for various common measurements",
      icon: <RepeatIcon />,
    },
    {
      name: "Essential Equations",
      url: "equations",
      description:
        "A list of essential engineering & math equations, along with their assumptions, application and usage.",
      icon: <ScienceIcon />,
    },
  ];

  const displayAllTools = () => {
    return tools.map((item, i) => {
      return (
        <Card
          sx={{
            minWidth: 275,
            mx: "4px",
            padding: "10px",
          }}
          style={{
            cursor: "pointer",
          }}
          key={i}
          onClick={() => navigate(item.url)}
        >
          <CardContent style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              component="div"
              style={{ display: "flex", alignItems: "center" }}
            >
              {item.icon} {item.name}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
              {item.description}
            </Typography>

            <Button size="small" style={{ alignSelf: "flex-end" }}>
              <OutboundIcon /> EXPLORE
            </Button>
          </CardContent>
        </Card>
      );
    });
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setIsSearching(true);
    } else setIsSearching(false);
  }, [searchTerm]);

  const handleSearch = () => {
    navigate("/search", {
      state: { searchTerm: searchTerm },
    });
    setSearchTerm("");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="dashboard">
      <Typography variant="h3" style={{ textAlign: "center" }}>
        Science, Engineering & Mathematics Calculators
      </Typography>
      <TextField
        style={{ margin: 20, width: "75%" }}
        sx={{ fontWeight: "bold" }}
        id="outlined-basic"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                onClick={() => handleSearch()}
                style={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: "search",
          style: { fontWeight: "bold" },
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => handleEnter(e)}
        label={
          isSearching ? (
            ""
          ) : (
            <Typewriter
              infinite={true}
              text={[
                "Search for a calculator",
                "How long does it take a car to stop?",
                "What is my weight on Jupiter?",
                "What is the percent composition of sulfuric acid?",
                "How does the pipe diameter affect volumetric flow rate?",
                "How do we predict the type of bond formed between hydrogen and fluorine?",
              ]}
              delay={100}
            />
          )
        }
        variant="outlined"
      />
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        Featured Calculators
      </Typography>

      <Box className="featured">{displayFeatured()}</Box>
      <Divider
        variant="inset"
        style={{ width: "100%", borderWidth: 1, margin: 20 }}
      />

      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        All Calculators
      </Typography>

      <Box sx={{ minWidth: 120 }} className="display-cards">
        {displayAllCalcs}
      </Box>
      <Divider
        variant="inset"
        style={{ width: "100%", borderWidth: 1, margin: 20 }}
      />
      <Typography variant="h3" style={{ alignSelf: "flex-start" }}>
        Tools & Resources
      </Typography>
      <Box sx={{ minWidth: 120 }} className="tools">
        {displayAllTools()}
      </Box>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MathJax } from "better-react-mathjax";

const ThermalResistance = () => {
  const [shape, setShape] = useState("hollow cylinder");
  const [material, setMaterial] = useState("Copper");
  const [length, setLength] = useState(10);
  const [lengthUnit, setLengthUnit] = useState("inches (in)");
  const [innerRad, setInnerRad] = useState(3);
  const [innerRadUnit, setInnerRadUnit] = useState("inches (in)");
  const [outerRad, setOuterRad] = useState(10);
  const [outerRadUnit, setOuterRadUnit] = useState("inches (in)");

  const [thickness, setThickness] = useState(5);
  const [thicknessUnit, setThicknessUnit] = useState("inches (in)");
  const [area, setArea] = useState(15);
  const [areaUnit, setAreaUnit] = useState("square inches (in2)");

  const [resistance, setResistance] = useState(null);

  const shapeChoices = ["hollow cylinder", "plate", "hollow sphere"];

  const distanceUnits = [
    "meters (m)",
    "feet (ft)",
    "inches (in)",
    "centimeters (cm)",
  ];

  const areaUnits = [
    "square meters (m2)",
    "square centimeters (cm2)",
    "square inches (in2)",
    "square feet (ft2)",
  ];

  const thermalConductivity = useMemo(() => {
    return {
      Air: 0.024,
      Water: 0.6,
      Aluminum: 205,
      Copper: 401,
      Iron: 80.2,
      Lead: 35.3,
      Glass: 1.0,
      Wood: 0.13,
      Brick: 0.6,
      Concrete: 1.7,
      "Insulation (Fiberglass)": 0.04,
      "Insulation (Foam)": 0.03,
      "Stainless Steel": 16.3,
      Silver: 429,
      Gold: 314,
      "Plastic (Polyethylene)": 0.36,
      "Plastic (Polypropylene)": 0.15,
      "Plastic (PVC)": 0.19,
      "Plastic (Polystyrene)": 0.03,
      "Plastic (Polyurethane)": 0.02,
    };
  }, []);

  const convertArea = (area, unit) => {
    if (unit === "square feet (ft2)") {
      return area / 10.764;
    } else if (unit === "square inches (in2)") {
      return area / 1550;
    } else if (unit === "square centimeters (cm2)") {
      return area / 10000;
    }
    return area;
  };

  const convertDistance = (dist, unit) => {
    if (unit === "feet (ft)") {
      return dist / 3.281;
    } else if (unit === "inches (in)") {
      return dist / 39.37;
    } else if (unit === "centimeters (cm)") {
      return dist / 100;
    }
    return dist;
  };

  useEffect(() => {
    if (shape === "plate") {
      const cThickness = convertDistance(thickness, thicknessUnit);
      const cArea = convertArea(area, areaUnit);
      const resis = +cThickness / (thermalConductivity[material] * +cArea);
      console.log(+cThickness / (thermalConductivity[material] * +cArea));
      setResistance(resis.toFixed(3));
    } else if (shape === "hollow cylinder") {
      const cLength = convertDistance(length, lengthUnit);
      const cInner = convertDistance(innerRad, innerRadUnit);
      const cOuter = convertDistance(outerRad, outerRadUnit);
      const resis =
        Math.log(cOuter / cInner) /
        (2 * 3.14 * cLength * thermalConductivity[material]);
      setResistance(resis);
    } else if (shape === "hollow sphere") {
      const cInner = convertDistance(innerRad, innerRadUnit);
      const cOuter = convertDistance(outerRad, outerRadUnit);
      const resis =
        (+cOuter - +cInner) /
        (4 * 3.14 * cInner * cOuter * thermalConductivity[material]);
      setResistance(resis);
    }
  }, [
    area,
    areaUnit,
    innerRad,
    innerRadUnit,
    outerRad,
    outerRadUnit,
    thickness,
    thicknessUnit,
    length,
    lengthUnit,
    thermalConductivity,
    material,
    shape,
  ]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Thermal Resistance</Typography>
        <Typography variant="h6">
          Determine the thermal resistance for various materials of different
          geometries.
        </Typography>
        <Box
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: "400px",
          }}
        >
          <Box className="resistance-input">
            <FormControl>
              <FormLabel id="condition">Shape of Object</FormLabel>
              <Select
                className="select-field"
                id="shape"
                value={shape}
                variant="standard"
                onChange={(e) => {
                  setShape(e.target.value);
                }}
              >
                {shapeChoices.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <hr></hr>
            </FormControl>
          </Box>

          <Box className="resistance-input">
            <FormControl>
              <FormLabel id="condition">Material of Object</FormLabel>
              <Select
                className="select-field"
                id="material"
                value={material}
                variant="standard"
                onChange={(e) => {
                  setMaterial(e.target.value);
                }}
              >
                {Object.keys(thermalConductivity).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <hr></hr>
            </FormControl>
          </Box>
          <hr />

          {shape === "hollow cylinder" ? (
            <div>
              <Box className="resistance-input">
                <TextField
                  label="Length"
                  className="text-field"
                  id="length"
                  variant="standard"
                  type="number"
                  value={length}
                  onChange={(e) => {
                    setLength(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="shape"
                  value={lengthUnit}
                  variant="standard"
                  onChange={(e) => {
                    setLengthUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box className="resistance-input">
                <TextField
                  label="Inner Radius"
                  className="text-field"
                  id="inner-rad"
                  variant="standard"
                  type="number"
                  value={innerRad}
                  onChange={(e) => {
                    setInnerRad(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="inner-rad-unit"
                  value={innerRadUnit}
                  variant="standard"
                  onChange={(e) => {
                    setInnerRadUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box className="resistance-input">
                <TextField
                  label="Outer Radius"
                  className="text-field"
                  id="outer-rad"
                  variant="standard"
                  type="number"
                  value={outerRad}
                  onChange={(e) => {
                    setOuterRad(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="outer-rad-unit"
                  value={outerRadUnit}
                  variant="standard"
                  onChange={(e) => {
                    setOuterRadUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : shape === "plate" ? (
            <div>
              <Box className="resistance-input">
                <TextField
                  label="Cross Sectional Area"
                  className="text-field"
                  id="area"
                  variant="standard"
                  type="number"
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="area-unit"
                  value={areaUnit}
                  variant="standard"
                  onChange={(e) => {
                    setAreaUnit(e.target.value);
                  }}
                >
                  {areaUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box className="resistance-input">
                <TextField
                  label="Thickness"
                  className="text-field"
                  id="thickness"
                  variant="standard"
                  type="number"
                  value={thickness}
                  onChange={(e) => {
                    setThickness(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="thickness-unit"
                  value={thicknessUnit}
                  variant="standard"
                  onChange={(e) => {
                    setThicknessUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : shape === "hollow sphere" ? (
            <div>
              <Box className="resistance-input">
                <TextField
                  label="Inner Radius"
                  className="text-field"
                  id="inner-rad"
                  variant="standard"
                  type="number"
                  value={innerRad}
                  onChange={(e) => {
                    setInnerRad(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="inner-rad-unit"
                  value={innerRadUnit}
                  variant="standard"
                  onChange={(e) => {
                    setInnerRadUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box className="resistance-input">
                <TextField
                  label="Outer Radius"
                  className="text-field"
                  id="outer-rad"
                  variant="standard"
                  type="number"
                  value={outerRad}
                  onChange={(e) => {
                    setOuterRad(e.target.value);
                  }}
                />
                <Select
                  className="select-field"
                  id="outer-rad-unit"
                  value={outerRadUnit}
                  variant="standard"
                  onChange={(e) => {
                    setOuterRadUnit(e.target.value);
                  }}
                >
                  {distanceUnits.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </div>
          ) : null}
        </Box>

        <Typography variant="body">
          Thermal Conductivity (W/(m &#215; &deg;C)):{" "}
          <span className="electro">{thermalConductivity[material]}</span>
        </Typography>
        <Typography variant="body">
          Thermal Resistance (&deg;C/W):{" "}
          <span className="electro">
            {resistance ? resistance.toFixed(3) : null}
          </span>
        </Typography>
      </Box>

      <Typography variant="h3">Thermal Resistance Explained</Typography>
      <Typography paragraph>
        Thermal resistance and thermal conductivity are crucial concepts in
        understanding heat transfer processes in materials. Thermal resistance R
        is a measure of how much a material impedes the flow of heat. It is
        analogous to electrical resistance in that it represents the opposition
        to the flow of thermal energy. Thermal resistance is influenced by
        factors such as material thickness, cross-sectional area, and the
        thermal conductivity of the material.
      </Typography>
      <Typography paragraph>
        Thermal conductivity k is a material property that quantifies its
        ability to conduct heat. It represents the rate at which heat can pass
        through a unit area of a material under a temperature gradient.
        Materials with high thermal conductivity allow heat to transfer quickly,
        while those with low thermal conductivity impede heat transfer. Metals,
        for example, typically have high thermal conductivity due to the free
        movement of electrons, which can efficiently carry thermal energy. In
        contrast, insulating materials like foam or fiberglass have low thermal
        conductivity because they trap air pockets, reducing the transfer of
        heat.
      </Typography>
      <Typography paragraph>
        The relationship between thermal resistance and thermal conductivity is
        inverse; that is, materials with high thermal conductivity have low
        thermal resistance and vice versa. This relationship can be expressed
        mathematically as:
      </Typography>

      <MathJax>{"${R} = {t \\over {k \\cdot A}}$"}</MathJax>
      <Typography>where</Typography>
      <Typography>R is the thermal resistance</Typography>

      <Typography>t is the thickness of the material</Typography>
      <Typography>k is the thermal conductivity of the material</Typography>
      <Typography>
        A is the cross-sectional area through which heat flows
      </Typography>

      <Typography paragraph>
        Understanding thermal resistance and thermal conductivity is essential
        in various engineering applications, such as building insulation,
        electronic device design, and thermal management systems. Engineers use
        these concepts to optimize the efficiency of heat transfer processes,
        minimize energy loss, and ensure the proper functioning of systems
        operating under different temperature conditions. Additionally,
        advancements in materials science continue to expand our ability to
        manipulate thermal properties, leading to innovations in heat
        dissipation, energy conservation, and thermal insulation technologies.
      </Typography>
    </div>
  );
};

export default ThermalResistance;

import React, { useState } from "react";
import electronegativity from "../data/ElectronegativityData";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ScienceIcon from "@mui/icons-material/Science";

const Electronegativity = () => {
  const [firstElement, setFirstElement] = useState("");
  const [secondElement, setSecondElement] = useState("");

  console.log(firstElement);

  const findBondNature = (x, y) => {
    const diff = Math.abs(x - y);
    if (diff > 1.8) {
      return "ionic";
    } else if (diff < 0.4) {
      return "nonpolar covalent";
    } else {
      return "polar covalent";
    }
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator">
        <Typography variant="h3">Electronegativity </Typography>
        <Typography variant="h6">
          What type of bond will be formed by these elements?{" "}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="element-one">Element 1</InputLabel>
          <Select
            className="select-field"
            labelId="element-one"
            variant="standard"
            id="element-one"
            value={firstElement}
            label="Element"
            onChange={(e) => setFirstElement(e.target.value)}
          >
            {Object.keys(electronegativity).map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="element-two">Element 2</InputLabel>
          <Select
            className="select-field"
            labelId="element-two"
            variant="standard"
            id="element-two"
            value={secondElement}
            label="Element"
            onChange={(e) => setSecondElement(e.target.value)}
          >
            {Object.keys(electronegativity).map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <Typography variant="body1" gutterBottom>
          First element electronegativity:{" "}
          <span className="electro">{electronegativity[firstElement]}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Second element electronegativity:{" "}
          <span className="electro">{electronegativity[secondElement]}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Difference in electronegativities:{" "}
          <span className="electro">
            {Math.abs(
              electronegativity[firstElement] - electronegativity[secondElement]
            ).toFixed(3)}
          </span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ScienceIcon sx={{ verticalAlign: "middle" }} />
          These two elements would form a{" "}
          <span className="electro">
            {" "}
            {findBondNature(
              electronegativity[firstElement],
              electronegativity[secondElement]
            )}
          </span>{" "}
          bond.
        </Typography>
      </Box>
      <Typography variant="h3">Electronegativity Explained</Typography>
      <Typography paragraph>
        Electronegativity is a fundamental property of elements that measures
        their ability to attract electrons in a chemical bond. It plays a
        crucial role in predicting the type of bond that forms between two
        elements in a compound. The electronegativity values, usually assigned
        based on the Pauling scale, range from 0.7 for cesium to 4.0 for
        fluorine. The larger the electronegativity difference between two
        elements, the more polar the bond, and the more likely an ionic or polar
        covalent bond will form.
      </Typography>
      <Typography paragraph>
        In general, when the electronegativity difference between two elements
        is large (typically greater than 1.7), an ionic bond is formed. In ionic
        bonding, electrons are transferred from the less electronegative element
        to the more electronegative one, resulting in the formation of
        positively charged cations and negatively charged anions. These ions are
        attracted to each other due to their opposite charges, forming an ionic
        compound with a characteristic crystalline structure.
      </Typography>
      <Typography paragraph>
        Conversely, when the electronegativity difference is smaller (between
        0.4 and 1.7), a polar covalent bond is likely to form. In polar covalent
        bonds, electrons are shared between the atoms, but the sharing is
        unequal due to the differing electronegativities. As a result, one atom
        attracts the shared electrons more strongly, creating a partial negative
        charge (δ-) on that atom and a partial positive charge (δ+) on the
        other. Water (H2O) is a classic example of a molecule with polar
        covalent bonds.
      </Typography>
      <Typography paragraph>
        When the electronegativity difference is very small (typically less than
        0.4), the bond is considered nonpolar covalent. In nonpolar covalent
        bonds, electrons are shared equally between the atoms, and there is no
        significant difference in charge distribution. Diatomic molecules like
        hydrogen (H2) or oxygen (O2) exhibit nonpolar covalent bonds.
      </Typography>
      <Typography paragraph>
        Understanding electronegativity and its role in bond formation provides
        valuable insights into the chemical behavior of substances. It helps
        predict the nature of chemical bonds, the type of compounds formed, and
        even influences the physical properties of materials. The concept of
        electronegativity is an essential tool for chemists in rationalizing and
        predicting the behavior of elements in various chemical environments.
      </Typography>
    </div>
  );
};

export default Electronegativity;

import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PieChart, { Series, Label, Legend } from "devextreme-react/pie-chart";
import WarningIcon from "@mui/icons-material/Warning";

const AtomicMass = () => {
  const [iso1, setIso1] = useState(0);
  const [iso2, setIso2] = useState(0);
  const [iso3, setIso3] = useState(0);
  const [iso4, setIso4] = useState(0);
  const [iso5, setIso5] = useState(0);

  const [isoMass1, setIsoMass1] = useState(0);
  const [isoMass2, setIsoMass2] = useState(0);
  const [isoMass3, setIsoMass3] = useState(0);
  const [isoMass4, setIsoMass4] = useState(0);
  const [isoMass5, setIsoMass5] = useState(0);

  const [showPercentError, setShowPercentError] = useState(true);
  let dataSource = [];

  const displayPercentInput = (n) => {
    return (
      <Box
        className="atomic-mass-input"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="body">Percentage of Isotope #{n}:</Typography>
        <TextField
          label="Percentage (%)"
          variant="standard"
          className="text-field"
          type="number"
          placeholder="%"
          // sx={{ width: "20ch" }}

          id={`percent${n}`}
          onChange={handlePercentChange}
        />
      </Box>
    );
  };

  const displayMassInput = (n) => {
    return (
      <Box
        className="atomic-mass-input"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="body">Mass of Isotope #{n}:</Typography>

        <TextField
          label="Mass (amu)"
          variant="standard"
          className="text-field"
          type="number"
          placeholder="amu"
          // sx={{ width: "20ch" }}
          id={`mass${n}`}
          onChange={handleMassChange}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (!showPercentError) {
      showPie();
    }
  });

  useEffect(() => {
    const percents = [iso1, iso2, iso3, iso4, iso5];
    const newArr = [];
    percents.forEach((percent) => {
      if (percent !== "") {
        newArr.push(parseInt(percent));
      }
    });
    const sum = newArr.reduce((sum, a) => sum + a, 0);
    if (sum === 100) {
      setShowPercentError(false);
    } else {
      setShowPercentError(true);
    }
  }, [iso1, iso2, iso3, iso4, iso5]);

  const isotopes = [
    [isoMass1, iso1],
    [isoMass2, iso2],
    [isoMass3, iso3],
    [isoMass4, iso4],
    [isoMass5, iso5],
  ];

  useEffect(() => {
    isotopes.forEach((item) => {
      if (item[1] !== "") {
        dataSource.push({
          argument: parseInt(item[0]),
          value: parseInt(item[1]),
        });
      }
    });
  });

  const showPie = () => (
    <PieChart
      className="pie"
      key="element-pie"
      title="Percent Composition by Mass"
      palette="Soft Pastel"
      sizeGroup="piesGroup"
      dataSource={dataSource}
    >
      <Series argumentField="argument" valueField="value">
        <Label visible={true} />
      </Series>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="right"
        rowCount={2}
      />
    </PieChart>
  );

  const handlePercentChange = () => {
    setIso1(document.querySelector("#percent1").value);
    setIso2(document.querySelector("#percent2").value);
    setIso3(document.querySelector("#percent3").value);
    setIso4(document.querySelector("#percent4").value);
    setIso5(document.querySelector("#percent5").value);
  };

  const handleMassChange = () => {
    setIsoMass1(document.querySelector("#mass1").value);
    setIsoMass2(document.querySelector("#mass2").value);
    setIsoMass3(document.querySelector("#mass3").value);
    setIsoMass4(document.querySelector("#mass4").value);
    setIsoMass5(document.querySelector("#mass5").value);
  };

  const calculateMass = () => {
    const mass =
      iso1 * isoMass1 +
      iso2 * isoMass2 +
      iso3 * isoMass3 +
      iso4 * isoMass4 +
      iso5 * isoMass5;
    return (mass / 100).toFixed(3);
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }} className="calculator" id="atomic-mass">
        <Typography variant="h3">Average Atomic Mass</Typography>
        <Typography variant="h6">
          What is the average atomic mass of an element based on the abundance
          percentage of each isotope?
        </Typography>
        {[1, 2, 3, 4, 5].map((item) => {
          return (
            <div>
              {displayMassInput(item)}
              {displayPercentInput(item)}
            </div>
          );
        })}

        {showPercentError ? (
          <Typography
            className="amu-error"
            variant="body"
            sx={{ marginTop: "20px" }}
          >
            <WarningIcon sx={{ verticalAlign: "middle" }} />
            The total percentages must add up to 100%. Please make sure both a
            mass & percentage are filled out for each isotope.
          </Typography>
        ) : (
          <Typography
            variant="body"
            sx={{ marginTop: "10px", fontSize: "1.2em" }}
          >
            The average atomic mass of this element is
            <span className="electro"> {calculateMass()} </span>amu.
          </Typography>
        )}
        {!showPercentError ? showPie() : null}
      </Box>

      <Typography variant="h3">Average Atomic Mass Explained</Typography>
      <Typography paragraph>
        Average atomic mass, also known as atomic weight, is a concept in
        chemistry that accounts for the presence of isotopes in the calculation
        of the mass of an element. Isotopes are atoms of the same element that
        have different numbers of neutrons, resulting in variations in atomic
        mass. The average atomic mass considers both the mass and the abundance
        of each isotope to provide a weighted average that reflects the
        distribution of isotopes in a naturally occurring sample.
      </Typography>
      <Typography paragraph>
        The atomic mass listed on the periodic table for each element is, in
        fact, the weighted average of the masses of all naturally occurring
        isotopes of that element. The calculation involves multiplying the mass
        of each isotope by its relative abundance (expressed as a decimal),
        summing these values for all isotopes, and obtaining the average.
      </Typography>
      <Typography paragraph>
        For example, chlorine has two stable isotopes: chlorine-35 (^35Cl) and
        chlorine-37 (^37Cl). The atomic mass listed on the periodic table is
        approximately 35.45 g/mol. This value is a weighted average, considering
        that ^35Cl is more abundant in nature. The calculation involves
        accounting for the natural abundance of each isotope in the chlorine
        sample.
      </Typography>
      <Typography paragraph>
        The concept of average atomic mass is particularly significant in
        chemistry, as it provides a more accurate representation of the mass of
        an element than simply using the mass of a single isotope. It is
        essential for precise stoichiometric calculations in chemical reactions
        and contributes to our understanding of the composition of naturally
        occurring elements. The ability to experimentally determine isotope
        abundances has advanced with technological developments, contributing to
        more accurate values for average atomic masses.
      </Typography>
    </div>
  );
};

export default AtomicMass;
